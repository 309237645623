'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}
/* eslint-disable no-console */


var styledPanel = '\n  #cloudinary-widget .panel.local {\n    margin:0;\n    height:350px;\n    padding:0;\n  }\n';
var styledModalOverlay = '\n  #cloudinary-overlay.modal {\n    background-color: transparent;\n  }\n';
var hideNavBar = '\n  #cloudinary-navbar {\n    display: none;\n  }\n';
var styledInlineModalOverlay = '\n  #cloudinary-overlay.inline .widget {\n    border: 1px solid white;\n  }\n';
var styledDragArea = '\n  #cloudinary-widget .drag_area {\n    height: 250px;\n    margin:10px;\n  }\n';
var styledDragAreaContent = '\n  #cloudinary-widget .drag_content {\n    margin-top:50px;\n  }\n';
var styledDragAreaContentLabel = '\n  #cloudinary-widget .panel.local .drag_area .drag_content .label {\n    font-family: Arial;\n    font-size:16px;\n    color:#0065DB;\n    font-weight:bold;\n    color:#0065DB;\n  }\n';
var styledDragAreaContentOr = '\n  #cloudinary-widget .or {\n    font-family: Arial;\n    font-size:16px;\n    color:#0065DB;\n    font-weight:bold;\n  }\n';
var styledUploadButton = '\n  #cloudinary-widget .upload_button_holder .button {\n    font-family: Arial;\n    font-size:16px;\n    background-color:#0065DB;\n    color:#FFFFFF;\n    border:0;\n    border-radius: 0;\n    font-weight:bold;\n  }\n';
var stylesheet = '' + styledModalOverlay + styledPanel + hideNavBar + styledInlineModalOverlay + styledDragArea + styledDragAreaContent + styledUploadButton + styledDragAreaContentOr + styledDragAreaContentLabel;

var MediaUploadContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MediaUploadContainer'
}).withConfig({
  displayName: 'MediaUpload__MediaUploadContainer',
  componentId: 'pj50et-0'
})(['max-height:350px;width:100%;overflow-y:hidden;height:350px;']);

var MediaUpload = function (_React$Component) {
  _inherits(MediaUpload, _React$Component);

  function MediaUpload() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, MediaUpload);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = MediaUpload.__proto__ || Object.getPrototypeOf(MediaUpload)).call.apply(_ref, [this].concat(args))), _this), _this.componentDidMount = function () {
      var _this$props = _this.props,
          onUploadMediaSuccess = _this$props.onUploadMediaSuccess,
          onUploadMediaFailure = _this$props.onUploadMediaFailure,
          allowedTypeFormats = _this$props.allowedTypeFormats,
          accountName = _this$props.accountName,
          preset = _this$props.preset,
          brandFolder = _this$props.brandFolder,
          tags = _this$props.tags,
          dropFileText = _this$props.dropFileText;
      _this.widget = window.cloudinary.openUploadWidget({
        cloud_name: accountName,
        upload_preset: preset,
        folder: brandFolder + '/content',
        tags: tags,
        theme: 'minimal',
        show_powered_by: false,
        stylesheet: stylesheet,
        sources: ['local'],
        client_allowed_formats: allowedTypeFormats,
        keep_widget_open: true,
        inline_container: '#' + _this.id,
        text: {
          'sources.local.drop_files': dropFileText
        }
      }, function (error, result) {
        if (error) {
          var intervalId = window.setInterval(function () {
            onUploadMediaFailure(error.message);
            window.clearInterval(intervalId);
          }, _this.timeout);
        } else {
          onUploadMediaSuccess(result);
        }
      });
    }, _this.componentWillUnmount = function () {
      // @ may need to do some sort clean up
      console.warn('MediaUpload Component WillUnmount', _this.widget);
    }, _this.id = 'id-' + Date.now().toString(), _this.widget = undefined, _this.timeout = 1500, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(MediaUpload, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(MediaUploadContainer, {
        id: this.id,
        'data-qa-hook': this.id + '-media-upload'
      });
    }
  }]);

  return MediaUpload;
}(_react2.default.Component);

MediaUpload.defaultProps = {
  onUploadMediaSuccess: function onUploadMediaSuccess() {},
  onUploadMediaFailure: function onUploadMediaFailure() {},
  allowedTypeFormats: ['png', 'gif', 'jpeg', 'svg', 'mp4'],

  /** any string accountName and preset values are required
   * for the widget to load successfully.
   * this is here for styleguide mainly
   */
  accountName: 'name',
  preset: 'preset',
  tags: [],
  dropFileText: 'Drop files here'
};
exports.default = MediaUpload;