'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ColourField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'ColourField__Container',
  componentId: 'sc-14nr5jc-0'
})(['display:flex;justify-content:flex-end;align-items:center;color:', ';'], function (props) {
  return props.theme.colours.lowlight;
});

var Box = _styledComponents2.default.div.withConfig({
  displayName: 'Box'
}).withConfig({
  displayName: 'ColourField__Box',
  componentId: 'sc-14nr5jc-1'
})(['width:24px;height:24px;background-color:', ';border:1px solid ', ';box-shadow:0px 0px 0px 3px ', ' inset;min-width:24px;'], function (_ref) {
  var value = _ref.value;
  return value;
}, function (props) {
  return props.theme.colours.secondary03;
}, function (props) {
  return props.theme.colours.highlight02;
});

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'Label'
}).withConfig({
  displayName: 'ColourField__Label',
  componentId: 'sc-14nr5jc-2'
})(['font-family:', ';font-size:', ';color:', ';text-align:right;margin-right:10px;min-width:35px;max-width:60px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;display:inline-block;'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontSizeContent;
}, function (props) {
  return props.theme.colours.highlight01;
});

function ColourField(_ref2) {
  var label = _ref2.label,
      value = _ref2.value;
  return _react2.default.createElement(Container, null, _react2.default.createElement(Label, null, label), _react2.default.createElement(Box, {
    value: value
  }));
}