'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormContainer;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledFormContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FormContainer__StyledFormContainer',
  componentId: 'sc-1kqwdr7-0'
})(['display:flex;flex-direction:row;flex-wrap:wrap;padding:', ';width:', ';'], function (props) {
  return props.theme.layout.doublePadding;
}, function (_ref) {
  var width = _ref.width;
  return width || 'auto';
});

function FormContainer(props) {
  return _react2.default.createElement(StyledFormContainer, props);
}