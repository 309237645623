// @flow
import { type Error } from '../types/error';

const LoginSuccess: 'LOGIN SUCCESS' = 'LOGIN SUCCESS';
type LoginSuccessAction = {| type: typeof LoginSuccess, payload: string |};
const loginSuccess = (token: string): LoginSuccessAction => ({
  type: LoginSuccess,
  payload: token,
});

const LoginFailure: 'LOGIN FAILURE' = 'LOGIN FAILURE';
type LoginFailureAction = {| type: typeof LoginFailure, payload: Object |};
const loginFailure = (error: Error): LoginFailureAction => ({
  type: LoginFailure,
  payload: error,
});

const Login: 'LOGIN' = 'LOGIN';
type LoginAction = {| type: typeof Login |};
const login = (): LoginAction => ({ type: Login });

const Logout: 'LOGOUT' = 'LOGOUT';
type LogoutAction = {| type: typeof Logout |};
const logout = (): LogoutAction => ({ type: Logout });

const Ready: 'READY' = 'READY';
type ReadyAction = { type: typeof Ready };
const ready = (): ReadyAction => ({ type: Ready });

export const constants = { LoginSuccess, LoginFailure, Login, Logout, Ready };
export const actions = { loginSuccess, loginFailure, login, logout, ready };

export type Action =
  | LoginSuccessAction
  | LoginFailureAction
  | LoginAction
  | LogoutAction
  | ReadyAction;
