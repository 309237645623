'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Link;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledLink = _styledComponents2.default.a.withConfig({
  displayName: 'Link__StyledLink',
  componentId: 'sc-13kzzza-0'
})(['font-family:', ';font-size:', ';color:', ';cursor:pointer;'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontSizeContent;
}, function (props) {
  return props.theme.colours.linkAction;
});

function Link(props) {
  return _react2.default.createElement(StyledLink, props);
}