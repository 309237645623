// @flow
import type { Language } from '../reducers/language';
import type { Error } from '../types/error';

const LoadLanguages: 'LANGUAGES LOAD' = 'LANGUAGES LOAD';
type LoadLanguagesAction = { type: typeof LoadLanguages };
const loadLanguages = (): LoadLanguagesAction => ({ type: LoadLanguages });

const LoadLanguagesSuccess: 'LANGUAGES LOAD SUCCESS' = 'LANGUAGES LOAD SUCCESS';
type LoadLanguagesSuccessAction = {
  type: typeof LoadLanguagesSuccess,
  payload: Language[],
};
const loadLanguagesSuccess = (
  languages: Language[],
): LoadLanguagesSuccessAction => ({
  type: LoadLanguagesSuccess,
  payload: languages,
});

const LoadLanguagesFailure: 'LANGUAGES LOAD FAILURE' = 'LANGUAGES LOAD FAILURE';
type LoadLanguagesFailureAction = {
  type: typeof LoadLanguagesFailure,
  payload: Error,
};
const loadLanguagesFailure = (error: Error): LoadLanguagesFailureAction => ({
  type: LoadLanguagesFailure,
  payload: error,
});

export const constants = {
  LoadLanguages,
  LoadLanguagesSuccess,
  LoadLanguagesFailure,
};

export const actions = {
  loadLanguages,
  loadLanguagesSuccess,
  loadLanguagesFailure,
};

export type Action =
  | LoadLanguagesAction
  | LoadLanguagesSuccessAction
  | LoadLanguagesFailureAction;
