'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

exports.default = Select;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledSelect = _styledComponents2.default.select.withConfig({
  displayName: 'Select__StyledSelect',
  componentId: 'sc-1vdxkk5-0'
})(['background-color:', ';color:', ';border:0;outline-offset:0;', ' ', ' outline:1px inset ', ';font-size:', ';&:focus{border-color:', ';outline:0;}&:focus{outline:1px inset ', ';}'], function (_ref) {
  var theme = _ref.theme;
  return theme.colours.primary03;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colours.secondary06;
}, function (props) {
  return props.thin ? 'padding: 2px 4px;' : 'height: 30px;';
}, function (props) {
  return !props.thin && 'line-height: 30px;';
}, function (props) {
  return props.theme.colours.secondary03;
}, function (props) {
  return props.theme.fontSizeFormControls;
}, function (props) {
  return props.theme.colours.connectAutoBlue;
}, function (props) {
  return props.theme.colours.secondary03;
});

function Select(props) {
  var options = props.options.map(function (option) {
    return _react2.default.createElement('option', {
      key: option.value,
      value: option.value
    }, option.label);
  });
  return _react2.default.createElement(StyledSelect, _extends({
    defaultValue: props.selectedValue,
    name: props.name,
    onChange: props.onChange
  }, props), _react2.default.createElement('option', {
    value: ''
  }, props.placeholder), options);
}

Select.defaultProps = {
  placeholder: '',
  selectedValue: '',
  thin: false
};