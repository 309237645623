'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FieldList;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledFieldList = _styledComponents2.default.ul.withConfig({
  displayName: 'FieldList__StyledFieldList',
  componentId: 'sc-17bzyvt-0'
})(['list-style-type:none;padding:0;']);

function FieldList(props) {
  return _react2.default.createElement(StyledFieldList, props);
}