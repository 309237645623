'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var TabsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TabsContainer'
}).withConfig({
  displayName: 'Tabs__TabsContainer',
  componentId: 'sc-1ru4ne9-0'
})(['width:100%;border:1px solid ', ';background-color:', ';'], function (props) {
  return props.theme.colours.secondary02;
}, function (props) {
  return props.theme.colours.secondary01;
});

var Tab = _styledComponents2.default.button.withConfig({
  displayName: 'Tab'
}).withConfig({
  displayName: 'Tabs__Tab',
  componentId: 'sc-1ru4ne9-1'
})(['border:0;background-color:', ';', ' font-weight:600;font-size:', ' outline:none;margin-top:1px;min-width:100px;text-transform:capitalize;padding:0;height:40px;cursor:pointer;'], function (props) {
  return props.theme.colours.secondary01;
}, function (props) {
  return '' + (props.active ? '\n    color: ' + props.theme.colours.primary01 + ';\n    border-bottom: 3px solid;\n  ' : '');
}, function (props) {
  return '' + props.theme.fontSizeContent;
});

var TabContent = _styledComponents2.default.div.withConfig({
  displayName: 'TabContent'
}).withConfig({
  displayName: 'Tabs__TabContent',
  componentId: 'sc-1ru4ne9-2'
})(['display:', ';padding-top:10px;'], function (props) {
  return props.active ? 'block' : 'none';
});

var Tabs = function (_React$Component) {
  _inherits(Tabs, _React$Component);

  function Tabs() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Tabs);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Tabs.__proto__ || Object.getPrototypeOf(Tabs)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      activeIndex: 0
    }, _this.onTabClick = function (activeIndex) {
      return _this.setState(function () {
        return {
          activeIndex: activeIndex
        };
      });
    }, _this.render = function () {
      var items = _this.props.items;
      var activeIndex = _this.state.activeIndex;
      return _react2.default.createElement('div', null, _react2.default.createElement(TabsContainer, null, items.map(function (_ref2, index) {
        var title = _ref2.title;
        return _react2.default.createElement(Tab, {
          key: title + '-' + index.toString(),
          onClick: function onClick() {
            return _this.onTabClick(index);
          },
          active: index === activeIndex,
          'data-qa-hook': title + '-tab'
        }, title);
      })), _react2.default.createElement('div', null, items.map(function (_ref3, index) {
        var title = _ref3.title,
            Component = _ref3.component;
        return _react2.default.createElement(TabContent, {
          key: title + '-' + index.toString(),
          active: index === activeIndex
        }, _react2.default.createElement(Component, null));
      })));
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return Tabs;
}(_react2.default.Component);

Tabs.defaultProps = {
  items: []
};
exports.default = Tabs;