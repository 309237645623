/* this may change to /app in prod */
const ROOT = '/';

const ROUTE_BRANDS = 'brands';
const ROUTE_SITES = 'sites';
const BRAND_ID = ':brandId';
const SITE_ID = ':siteId';

const CREATE = 'create';

const INDEX = ROOT;
const BRANDS = [ROOT === '/' ? '' : ROOT, ROUTE_BRANDS].join('/');
const CREATE_BRAND = [ROOT === '/' ? '' : ROOT, ROUTE_BRANDS, CREATE].join('/');
const EDIT_BRAND = brandId =>
  [ROOT === '/' ? '' : ROOT, ROUTE_BRANDS, brandId].join('/');
const SITES = brandId =>
  [ROOT === '/' ? '' : ROOT, ROUTE_BRANDS, brandId, ROUTE_SITES].join('/');
const CREATE_SITE = brandId =>
  [ROOT === '/' ? '' : ROOT, ROUTE_BRANDS, brandId, ROUTE_SITES, CREATE].join(
    '/',
  );
const EDIT_SITE = (brandId, siteId) =>
  [ROOT === '/' ? '' : ROOT, ROUTE_BRANDS, brandId, ROUTE_SITES, siteId].join(
    '/',
  );

const ROUTES = {
  INDEX,
  BRANDS,
  CREATE_BRAND,
  EDIT_BRAND: EDIT_BRAND(BRAND_ID),
  SITES: SITES(BRAND_ID),
  CREATE_SITE: CREATE_SITE(BRAND_ID),
  EDIT_SITE: EDIT_SITE(BRAND_ID, SITE_ID),
};

export const TO = {
  EDIT_BRAND,
  SITES,
  CREATE_SITE,
  EDIT_SITE,
};

export function getParentUrl(currentPath, parent) {
  // currentPath looks like /brands/45453465/whatever
  // parent looks like /brands/:brandId etc etc
  const routes = [ROOT, BRANDS, `${BRANDS}/`];
  if (routes.includes(currentPath)) return '';

  const brandId = currentPath.split('/')[2];
  const newUrl = parent.replace(':brandId', brandId);
  return newUrl;
}

export default ROUTES;
