'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormGroup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledFormGroup = _styledComponents2.default.div.withConfig({
  displayName: 'FormGroup__StyledFormGroup',
  componentId: 'sc-192ep2y-0'
})(['display:flex;', ';'], function (props) {
  return props.horizontal ? (0, _styledComponents.css)(['justify-content:flex-start;margin-bottom:', ';'], function (_ref) {
    var theme = _ref.theme;
    return theme.layout.halfPadding;
  }) : (0, _styledComponents.css)(['flex-direction:column;margin-bottom:', ';'], function (_ref2) {
    var theme = _ref2.theme;
    return theme.layout.standardPadding;
  });
});

function FormGroup(props) {
  return _react2.default.createElement(StyledFormGroup, props);
}

FormGroup.defaultProps = {
  horizontal: false
};