'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SectionMenuHeader;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FlexWrapper = require('./FlexWrapper');

var _FlexWrapper2 = _interopRequireDefault(_FlexWrapper);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'Title'
}).withConfig({
  displayName: 'SectionMenuHeader__Title',
  componentId: 'sc-10jvjmv-0'
})(['position:relative;z-index:50;width:100%;font-family:', ';color:', ';display:flex;font-size:14px;justify-content:space-between;align-items:center;box-sizing:border-box;height:42px;font-weight:600;padding:', ';background-color:', ';', ';'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.colours.secondary03;
}, function (props) {
  return props.theme.layout.standardPadding;
}, function (props) {
  return props.theme.colours.primary03;
}, function (_ref) {
  var theme = _ref.theme;
  return theme.mixins.boxShadow('#000', 0.86, 0, 4, 20, 0, 0);
});

function SectionMenuHeader(_ref2) {
  var children = _ref2.children;
  return _react2.default.createElement(Title, null, _react2.default.createElement(_FlexWrapper2.default, null, children));
}