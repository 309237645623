'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SectionMenuItem;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _chevronRight = require('react-icons/lib/io/chevron-right');

var _chevronRight2 = _interopRequireDefault(_chevronRight);

var _FlexWrapper = require('./FlexWrapper');

var _FlexWrapper2 = _interopRequireDefault(_FlexWrapper);

var _IconBox = require('./IconBox');

var _IconBox2 = _interopRequireDefault(_IconBox);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var itemBorder = function itemBorder(shadowColour) {
  return '1px solid ' + shadowColour;
};

var Body = _styledComponents2.default.div.withConfig({
  displayName: 'Title'
}).withConfig({
  displayName: 'SectionMenuItem__Body',
  componentId: 'jrn3gl-0'
})(['width:100%;font-family:', ';color:', ';display:flex;font-size:14px;justify-content:space-between;align-items:center;box-sizing:border-box;height:56px;padding:', ';background-color:', ';border-bottom:', ';cursor:pointer;user-select:none;'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.colours.secondary03;
}, function (props) {
  return props.theme.layout.standardPadding;
}, function (props) {
  return props.theme.colours.primary03;
}, function (props) {
  return props.withBorder ? itemBorder(props.theme.colours.secondary02) : 'none';
});

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'Title'
}).withConfig({
  displayName: 'SectionMenuItem__Title',
  componentId: 'jrn3gl-1'
})(['color:', ';font-size:14px;line-height:17px;font-weight:600;'], function (props) {
  return props.theme.colours.secondary03;
});

var Subtitle = _styledComponents2.default.div.withConfig({
  displayName: 'Subtitle'
}).withConfig({
  displayName: 'SectionMenuItem__Subtitle',
  componentId: 'jrn3gl-2'
})(['color:', ';font-size:12px;line-height:15px;'], function (props) {
  return props.theme.colours.secondary02;
});

var PlusIcon = _styledComponents2.default.img.withConfig({
  displayName: 'SectionMenuItem__PlusIcon',
  componentId: 'jrn3gl-3'
})(['height:1em;color:', ';'], function (_ref) {
  var theme = _ref.theme;
  return '' + theme.colours.secondary03;
});

function SectionMenuItem(_ref2) {
  var children = _ref2.children,
      onClick = _ref2.onClick,
      subtitle = _ref2.subtitle,
      withBorder = _ref2.withBorder,
      withoutChevron = _ref2.withoutChevron,
      onPlusClick = _ref2.onPlusClick;
  return _react2.default.createElement(Body, {
    onClick: onClick,
    withBorder: withBorder
  }, _react2.default.createElement(_FlexWrapper2.default, null, _react2.default.createElement(Title, null, children), subtitle && _react2.default.createElement(Subtitle, null, ' ', subtitle, ' ')), onPlusClick && _react2.default.createElement(_IconBox2.default, null, _react2.default.createElement(PlusIcon, {
    src: 'https://res.cloudinary.com/motortrak/image/upload/v1566479519/editor/plus-newpage.svg',
    onClick: onPlusClick
  })), !withoutChevron && _react2.default.createElement(_IconBox2.default, null, _react2.default.createElement(_chevronRight2.default, null)));
}

SectionMenuItem.defaultProps = {
  subtitle: '',
  withBorder: false,
  withoutChevron: false
};