'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TextAlignment;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconRadioGroup = require('../IconRadioGroup');

var _IconRadioGroup2 = _interopRequireDefault(_IconRadioGroup);

var _LeftAlignDisabled = require('./LeftAlignDisabled');

var _LeftAlignDisabled2 = _interopRequireDefault(_LeftAlignDisabled);

var _MiddleAlignDisabled = require('./MiddleAlignDisabled');

var _MiddleAlignDisabled2 = _interopRequireDefault(_MiddleAlignDisabled);

var _RightAlignDisabled = require('./RightAlignDisabled');

var _RightAlignDisabled2 = _interopRequireDefault(_RightAlignDisabled);

var _LeftAlignEnabled = require('./LeftAlignEnabled');

var _LeftAlignEnabled2 = _interopRequireDefault(_LeftAlignEnabled);

var _MiddleAlignEnabled = require('./MiddleAlignEnabled');

var _MiddleAlignEnabled2 = _interopRequireDefault(_MiddleAlignEnabled);

var _RightAlignEnabled = require('./RightAlignEnabled');

var _RightAlignEnabled2 = _interopRequireDefault(_RightAlignEnabled);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var all = [{
  icons: {
    base: _LeftAlignDisabled2.default,
    active: _LeftAlignEnabled2.default,
    disabled: 'blue'
  },
  value: 'left'
}, {
  icons: {
    base: _MiddleAlignDisabled2.default,
    active: _MiddleAlignEnabled2.default,
    disabled: 'blue'
  },
  value: 'center'
}, {
  icons: {
    base: _RightAlignDisabled2.default,
    active: _RightAlignEnabled2.default,
    disabled: 'blue'
  },
  value: 'right'
}];
var left = all[0],
    middle = all[1],
    right = all[2];
var options = [left, middle, right];
var twoOptions = [left, right];

function TextAlignment(_ref) {
  var value = _ref.value,
      name = _ref.name,
      open = _ref.open,
      onChange = _ref.onChange,
      onClose = _ref.onClose,
      onOpen = _ref.onOpen,
      inline = _ref.inline,
      leftRightOnly = _ref.leftRightOnly;
  var usedOptions = leftRightOnly ? twoOptions : options;
  var objValue = usedOptions.find(function (o) {
    return o.value === value;
  }) || usedOptions[0];
  return _react2.default.createElement(_IconRadioGroup2.default, {
    name: name + 'Alignment',
    options: usedOptions,
    selectedValue: objValue,
    open: inline || open,
    onChange: onChange,
    onClose: onClose,
    onOpen: onOpen,
    inline: inline
  });
}

TextAlignment.defaultProps = {
  inline: false,
  leftRightOnly: false,
  onChange: null,
  onOpen: null,
  onClose: null
};