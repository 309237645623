'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Loader;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _loadC = require('react-icons/lib/io/load-c');

var _loadC2 = _interopRequireDefault(_loadC);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var spin = (0, _styledComponents.keyframes)(['from{transform:rotate(0deg);}to{transform:rotate(359deg);}']);

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Loader__Container',
  componentId: 'sc-1o8cca0-0'
})(['visibility:', ';'], function (_ref) {
  var visible = _ref.visible;
  return visible ? 'visible' : 'hidden';
});

var Spinner = (0, _styledComponents2.default)(_loadC2.default).withConfig({
  displayName: 'Loader__Spinner',
  componentId: 'sc-1o8cca0-1'
})(['width:', ';height:', ';color:', ';animation:', ' 0.6s infinite linear;'], function (_ref2) {
  var size = _ref2.size;
  return size + 'px';
}, function (_ref3) {
  var size = _ref3.size;
  return size + 'px';
}, function (props) {
  return '' + props.theme.colours.connectAutoBlue;
}, spin);

function Loader(_ref4) {
  var visible = _ref4.visible,
      size = _ref4.size;
  return _react2.default.createElement(Container, {
    visible: visible
  }, _react2.default.createElement(Spinner, {
    size: size
  }));
}

Loader.defaultProps = {
  size: 20
};