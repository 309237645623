"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var DeleteIcon = function DeleteIcon(props) {
  return _react2.default.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 14 14"
  }, props), _react2.default.createElement("path", {
    d: "M6.214 1c-.864 0-1.571.675-1.571 1.5H3.07C2.207 2.5 1.5 3.175 1.5 4h11c0-.825-.707-1.5-1.571-1.5H9.357c0-.825-.707-1.5-1.571-1.5H6.214zM3 12.696c0 .176.128.304.303.304h7.394a.294.294 0 0 0 .303-.304V5H9.403v5.6c0 .448-.351.8-.798.8a.791.791 0 0 1-.799-.8V5H6.21v5.6c0 .448-.352.8-.799.8a.791.791 0 0 1-.798-.8V5H3.016L3 12.696z",
    fill: "#0065DB",
    fillRule: "nonzero"
  }));
};

exports.default = DeleteIcon;