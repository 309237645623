"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var UppercaseDisabled = function UppercaseDisabled(props) {
  return _react2.default.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18"
  }, props), _react2.default.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react2.default.createElement("path", {
    stroke: "#757575",
    d: "M1.5 1.5h15v15h-15z"
  }), _react2.default.createElement("path", {
    d: "M9 11.991h-.894a.391.391 0 0 1-.387-.262l-.465-1.27H4.677l-.465 1.27a.407.407 0 0 1-.383.262H2.93L5.374 5.77h1.183L9 11.991zM4.975 9.642h1.983L6.2 7.572a9.45 9.45 0 0 1-.237-.73c-.04.146-.08.282-.118.408a4.574 4.574 0 0 1-.114.331l-.757 2.061zm10.136 2.35h-.895a.391.391 0 0 1-.387-.263l-.465-1.27h-2.577l-.465 1.27a.407.407 0 0 1-.382.262h-.9l2.444-6.221h1.183l2.444 6.221zm-4.027-2.35h1.983l-.757-2.07a9.45 9.45 0 0 1-.236-.73c-.04.146-.08.282-.119.408a4.574 4.574 0 0 1-.114.331l-.757 2.061z",
    fill: "#757575"
  })));
};

exports.default = UppercaseDisabled;