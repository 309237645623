import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/es/integration/react';
import theme from './theme';
import store, { persistor } from './state/store';
import history from './history';
import ROUTES from './constants/routes';

import App from './containers/App';
import BrandsContainer from './containers/BrandsContainer';
import SitesContainer from './containers/SitesContainer';
import SiteContainer from './containers/SiteContainer';
import BrandContainer from './containers/BrandContainer';
import RestrictedPage from './containers/LogoutWrapper';

import ScrollToTop from './components/ScrollToTop';

export default function Root() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <ScrollToTop>
            <ThemeProvider theme={theme}>
              <App>
                <Switch>
                  <Route path={ROUTES.CREATE_SITE} exact>
                    <RestrictedPage parent={ROUTES.SITES}>
                      <Route component={SiteContainer} />
                    </RestrictedPage>
                  </Route>
                  <Route path={ROUTES.EDIT_SITE} exact>
                    <RestrictedPage parent={ROUTES.SITES}>
                      <Route component={SiteContainer} />
                    </RestrictedPage>
                  </Route>
                  <Route path={ROUTES.SITES} exact>
                    <RestrictedPage parent={ROUTES.INDEX}>
                      <Route component={SitesContainer} />
                    </RestrictedPage>
                  </Route>
                  <Route path={ROUTES.CREATE_BRAND} exact>
                    <RestrictedPage parent={ROUTES.INDEX}>
                      <Route component={BrandContainer} />
                    </RestrictedPage>
                  </Route>
                  <Route path={ROUTES.EDIT_BRAND} exact>
                    <RestrictedPage parent={ROUTES.INDEX}>
                      <Route component={BrandContainer} />
                    </RestrictedPage>
                  </Route>
                  <Route path={ROUTES.INDEX}>
                    <RestrictedPage parent={ROUTES.INDEX}>
                      <Route component={BrandsContainer} />
                    </RestrictedPage>
                  </Route>
                </Switch>
              </App>
            </ThemeProvider>
          </ScrollToTop>
        </Router>
      </PersistGate>
    </Provider>
  );
}
