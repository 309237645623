'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BannerItem;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledBannerItem = _styledComponents2.default.div.withConfig({
  displayName: 'BannerItem__StyledBannerItem',
  componentId: 'sc-35cn1w-0'
})(['display:flex;flex:1 0 0%;flex-direction:row;justify-content:space-between;align-items:center;']);

function BannerItem(props) {
  return _react2.default.createElement(StyledBannerItem, props);
}