// @flow
import { constants, type Action } from '../actions/site';
import { type Error } from '../types/error';

export type Site = {
  id: string,
  name: string,
};

export type PartialSite = {
  id?: string,
  name: string,
};

export type State = {
  isLoading: boolean,
  error: ?Error,
  sites: { [id: $PropertyType<Site, 'id'>]: Site },
};

const initialState: State = {
  isLoading: false,
  error: null,
  sites: {},
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.CreateSite:
    case constants.LoadSite:
    case constants.LoadSites:
    case constants.UpdateSite:
      return { ...state, isLoading: true };

    case constants.LoadSitesSuccessful: {
      const sites = action.payload;

      return {
        ...state,
        sites: sites.reduce((acc, s) => ({ ...acc, [s.id]: s }), {}),
        isLoading: false,
        error: null,
      };
    }

    case constants.CreateSiteSuccessful:
    case constants.UpdateSiteSuccessful:
    case constants.LoadSiteSuccess: {
      const site = action.payload;

      return {
        ...state,
        sites: { ...state.sites, [site.id]: site },
        isLoading: false,
        error: null,
      };
    }

    case constants.CreateSiteFailure:
    case constants.LoadSiteFailure:
    case constants.LoadSitesFailure:
    case constants.UpdateSiteFailure:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
}
