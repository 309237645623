'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LargeIconRadioGroup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'LargeIconRadioGroup__Container',
  componentId: 'sc-1owh9b-0'
})(['font-family:', ';border:2px solid ', ';color:', ';display:flex;justify-content:space-between;padding:0;'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.colours.connectAutoBlue;
}, function (props) {
  return props.theme.colours.highlight01;
});

var RadioLabel = _styledComponents2.default.label.withConfig({
  displayName: 'RadioLabel'
}).withConfig({
  displayName: 'LargeIconRadioGroup__RadioLabel',
  componentId: 'sc-1owh9b-1'
})(['background:', ';color:', ';font-size:32px;font-weight:600;display:flex;flex:1 0 0%;align-items:center;justify-content:center;cursor:pointer;'], function (_ref) {
  var theme = _ref.theme,
      selected = _ref.selected;
  return selected ? theme.colours.connectAutoBlue : theme.colours.highlight02;
}, function (_ref2) {
  var theme = _ref2.theme,
      selected = _ref2.selected;
  return selected ? theme.colours.highlight02 : theme.colours.highlight01;
});

var ElementWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'ElementWrapper'
}).withConfig({
  displayName: 'LargeIconRadioGroup__ElementWrapper',
  componentId: 'sc-1owh9b-2'
})(['display:flex;transform:', ';'], function (_ref3) {
  var rotate = _ref3.rotate;
  return rotate ? 'rotate(' + rotate + 'deg)' : 'none';
});

var HiddenRadioButton = _styledComponents2.default.input.withConfig({
  displayName: 'HiddenRadioButton'
}).withConfig({
  displayName: 'LargeIconRadioGroup__HiddenRadioButton',
  componentId: 'sc-1owh9b-3'
})(['display:none;']);

function LargeIconRadioGroup(_ref4) {
  var options = _ref4.options,
      selected = _ref4.selected,
      onChange = _ref4.onChange,
      name = _ref4.name;
  return _react2.default.createElement(Container, null, options.map(function (_ref5) {
    var value = _ref5.value,
        element = _ref5.element,
        rotate = _ref5.rotate;
    return _react2.default.createElement(RadioLabel, {
      key: value,
      selected: value === selected
    }, _react2.default.createElement(ElementWrapper, {
      rotate: rotate
    }, element), _react2.default.createElement(HiddenRadioButton, {
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (e) {
        return onChange(e.target.value);
      }),
      type: 'radio',
      name: name,
      value: value
    }));
  }));
}

LargeIconRadioGroup.defaultProps = {
  options: [],
  selected: null
};