'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

exports.default = InputField;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _InlineError = require('./InlineError');

var _InlineError2 = _interopRequireDefault(_InlineError);

var _Input = require('./Input');

var _Input2 = _interopRequireDefault(_Input);

var _InputLabel = require('./InputLabel');

var _InputLabel2 = _interopRequireDefault(_InputLabel);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var InputContainer = _styledComponents2.default.div.withConfig({
  displayName: 'InputField__InputContainer',
  componentId: 'j46vfz-0'
})(['display:flex;flex-direction:row;justify-content:flex-end;']);

var InputFieldContainer = _styledComponents2.default.div.withConfig({
  displayName: 'InputField__InputFieldContainer',
  componentId: 'j46vfz-1'
})(['display:flex;flex-direction:column;flex-grow:', ';'], function (_ref) {
  var inputFlexMode = _ref.inputFlexMode;
  return '' + inputFlexMode;
});

function InputField(_ref2) {
  var input = _ref2.input,
      _ref2$meta = _ref2.meta,
      touched = _ref2$meta.touched,
      error = _ref2$meta.error,
      type = _ref2.type,
      large = _ref2.large,
      placeholder = _ref2.placeholder,
      inputLabel = _ref2.inputLabel,
      inputLabelPosition = _ref2.inputLabelPosition,
      inputWidth = _ref2.inputWidth,
      maxInputWidth = _ref2.maxInputWidth,
      disabled = _ref2.disabled,
      inputFlexMode = _ref2.inputFlexMode,
      min = _ref2.min,
      max = _ref2.max;
  return _react2.default.createElement(InputFieldContainer, {
    inputFlexMode: inputFlexMode
  }, _react2.default.createElement(InputContainer, null, _react2.default.createElement(_Input2.default, _extends({
    inputWidth: inputWidth,
    maxInputWidth: maxInputWidth,
    large: large,
    hasInputLabel: !!inputLabel,
    inputLabelPosition: inputLabelPosition,
    placeholder: placeholder,
    type: type
  }, input, {
    error: touched && error,
    disabled: disabled,
    prefix: '',
    min: min,
    max: max
  })), inputLabel && _react2.default.createElement(_InputLabel2.default, {
    error: touched && error,
    large: large,
    inputLabelPosition: inputLabelPosition
  }, inputLabel)), touched && error && _react2.default.createElement(_InlineError2.default, null, error));
}

InputField.defaultProps = {
  input: {},
  meta: {
    touched: false,
    error: ''
  },
  large: false,
  type: 'string',
  placeholder: '',
  inputLabel: '',
  inputWidth: null,
  maxInputWidth: null,
  inputLabelPosition: 'left',
  inputFlexMode: '0'
};