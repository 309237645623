'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Box;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledBox = _styledComponents2.default.div.withConfig({
  displayName: 'Box__StyledBox',
  componentId: 'sc-1flormi-0'
})(['display:flex;flex-direction:column;padding:', ';font-family:', ';font-size:', ';background:', ';margin:', ';width:240px;height:180px;'], function (props) {
  return props.theme.layout.standardPadding;
}, function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontSizeSubHeader;
}, function (props) {
  return props.theme.colours.secondary02;
}, function (props) {
  return props.theme.layout.doublePadding;
});

function Box(props) {
  return _react2.default.createElement(StyledBox, props);
}