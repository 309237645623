'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = GlobalSettings;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _GlobalSettingsIcon = require('./GlobalSettingsIcon');

var _GlobalSettingsIcon2 = _interopRequireDefault(_GlobalSettingsIcon);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledGlobalSettings = _styledComponents2.default.div.withConfig({
  displayName: 'GlobalSettings__StyledGlobalSettings',
  componentId: 'sc-1tph8d9-0'
})(['display:flex;align-items:center;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'GlobalSettings__IconWrapper',
  componentId: 'sc-1tph8d9-1'
})(['height:1.5em;margin-right:10px;']);

function GlobalSettings() {
  return _react2.default.createElement(StyledGlobalSettings, null, _react2.default.createElement(IconWrapper, null, _react2.default.createElement(_GlobalSettingsIcon2.default, null)), 'Global Settings');
}