'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Dropdown;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

require('react-select/dist/react-select.css');

var _chevronDown = require('react-icons/lib/io/chevron-down');

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require('react-icons/lib/io/chevron-up');

var _chevronUp2 = _interopRequireDefault(_chevronUp);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ArrowButton = _styledComponents2.default.button.withConfig({
  displayName: 'Dropdown__ArrowButton',
  componentId: 'sc-60fuwz-0'
})(['background:transparent;border:none;padding-top:0;']);

var IsOpenIcon = (0, _styledComponents2.default)(_chevronUp2.default).withConfig({
  displayName: 'Dropdown__IsOpenIcon',
  componentId: 'sc-60fuwz-1'
})(['displayname:\'IsOpenIcon\';']);
var IsClosedIcon = (0, _styledComponents2.default)(_chevronDown2.default).withConfig({
  displayName: 'Dropdown__IsClosedIcon',
  componentId: 'sc-60fuwz-2'
})(['displayname:\'IsClosedIcon\';']);

var defaultArrows = function defaultArrows(_ref) {
  var isOpen = _ref.isOpen,
      onMouseDown = _ref.onMouseDown;
  return _react2.default.createElement(ArrowButton, {
    onMouseDown: onMouseDown
  }, isOpen ? _react2.default.createElement(IsOpenIcon, null) : _react2.default.createElement(IsClosedIcon, null));
};

var isDropdownCreatable = function isDropdownCreatable(creatable) {
  return (0, _styledComponents2.default)(creatable ? _reactSelect.Creatable : _reactSelect2.default).withConfig({
    displayName: 'Dropdown',
    componentId: 'sc-60fuwz-3'
  })(['displayName:\'StyledSelect\',border-radius:0;border:1px solid ', ';font-family:', ';font-size:', ';& .Select-control{height:', 'px;border-radius:0;border:none;&:hover{box-shadow:none;cursor:pointer;}& .Select-value{line-height:', 'px !important;}}& .Select-input{height:', 'px;}& .Select-placeholder{display:flex;align-items:center;}&.is-focused:not(.is-open) > .Select-control{box-shadow:none;}'], function (props) {
    return props.themeStyle === 'dark' ? props.theme.colours.secondary03 : props.theme.colours.secondary02;
  }, function (props) {
    return props.theme.fontFamily;
  }, function (props) {
    return props.theme.fontSizeContent;
  }, function (props) {
    return props.size;
  }, function (props) {
    return props.size;
  }, function (props) {
    return props.size;
  });
};

function Dropdown(_ref2) {
  var arrowRenderer = _ref2.arrowRenderer,
      disabled = _ref2.disabled,
      input = _ref2.input,
      options = _ref2.options,
      valueKey = _ref2.valueKey,
      valueRenderer = _ref2.valueRenderer,
      optionRenderer = _ref2.optionRenderer,
      clearable = _ref2.clearable,
      size = _ref2.size,
      themeStyle = _ref2.themeStyle,
      creatable = _ref2.creatable,
      onNewOption = _ref2.onNewOption,
      promptText = _ref2.promptText,
      isValidNewOption = _ref2.isValidNewOption;
  var StyledSelect = isDropdownCreatable(creatable);
  return _react2.default.createElement(StyledSelect, {
    value: input.value,
    options: options,
    onChange: input.onChange,
    disabled: disabled,
    arrowRenderer: arrowRenderer,
    valueKey: valueKey,
    valueRenderer: valueRenderer,
    optionRenderer: optionRenderer,
    clearable: clearable,
    size: size,
    themeStyle: themeStyle,
    placeholder: input.placeholder,
    newOptionCreator: onNewOption,
    promptTextCreator: promptText,
    showNewOptionAtTop: false,
    isValidNewOption: isValidNewOption,
    autosize: false
  });
}

Dropdown.defaultProps = {
  arrowRenderer: defaultArrows,
  disabled: false,
  valueKey: 'value',
  valueRenderer: function valueRenderer(i) {
    return i.label;
  },
  optionRenderer: function optionRenderer(i) {
    return i.label;
  },
  clearable: false,
  size: 24,
  themeStyle: 'dark',
  creatable: false,
  onNewOption: null,
  promptText: function promptText(label) {
    return 'Add "' + label + '"';
  },
  showNewOptionAtTop: true
};