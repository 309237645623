import { takeEvery, takeLatest, put, all } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist/lib/constants';

import { actions, constants } from '../state/actions/auth';

const tokenPatterns = [REHYDRATE, constants.LoginSuccess];

function getTokenFromAction(action) {
  switch (action.type) {
    case REHYDRATE:
      return (action.payload.auth && action.payload.auth.token) || null;
    case constants.LoginSuccess:
      return action.payload;
    default:
      return null;
  }
}

function* patternWatchWithToken(pattern, saga, action) {
  const token = getTokenFromAction(action);
  const effects = token ? [put(actions.ready())] : [];

  yield all([...effects, takeEvery(pattern, saga, token)]);
}

export default function watch(pattern, saga, injectToken) {
  // If this watcher does not need the token injected, just watch the pattern like normal.
  if (!injectToken) {
    return function* patternWatch() {
      yield takeEvery(pattern, saga);
    };
  }

  // Otherwise, instead watch for token changes. When the token changes, capture it and inject
  // it into the saga.
  return function* authWatch() {
    yield takeLatest(tokenPatterns, patternWatchWithToken, pattern, saga);
  };
}
