// @flow
import { REHYDRATE } from 'redux-persist/lib/constants';
import { constants } from '../actions/auth';
import type { Action } from '../actions/auth';
import { getExpiryTime } from '../../helpers/jwt';
import settings from '../../settings';
import { type Error } from '../types/error';

type Token = string;

export type State = {
  isLoginPending: boolean,
  isLoggedIn: boolean,
  token: ?Token,
  error: ?Error,
};

const initialState: State = {
  isLoginPending: false,
  isLoggedIn: !settings.authOn,
  token: null,
  error: null,
};

const isTokenStillValid = (token): boolean =>
  token ? getExpiryTime(token) > Date.now() : !settings.authOn;

export default function reducer(
  state: State = initialState,
  action: Action | { type: typeof REHYDRATE, payload: Object },
): State {
  switch (action.type) {
    case constants.Login:
      return { ...state, isLoginPending: true };
    case constants.LoginSuccess:
      return { ...state, isLoginPending: false, token: action.payload };
    case constants.LoginFailure:
      return { ...state, ...initialState, error: action.payload };
    case constants.Logout:
      return { ...state, ...initialState };
    case REHYDRATE: {
      const { auth = { token: null } } = action.payload || {};
      return {
        ...state,
        token: auth.token,
        isLoggedIn: isTokenStillValid(auth.token),
      };
    }
    case constants.Ready:
      return { ...state, isLoggedIn: isTokenStillValid(state.token) };
    default:
      return state;
  }
}
