'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Modal;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _transparentize = require('polished/lib/color/transparentize');

var _transparentize2 = _interopRequireDefault(_transparentize);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var aboveCentre = function aboveCentre(pos) {
  return pos.y < pos.screenHeight / 2;
};

var leftOfCentre = function leftOfCentre(pos) {
  return pos.x < pos.screenWidth / 2;
};

var topBottomCssString = function topBottomCssString(pos) {
  return aboveCentre(pos) ? 'top: ' + pos.y + 'px' : 'bottom: ' + (pos.screenHeight - pos.y) + 'px';
};

var leftRightCssString = function leftRightCssString(pos) {
  return leftOfCentre(pos) ? 'left: ' + pos.x + 'px' : 'right: ' + (pos.screenWidth - pos.x) + 'px';
};

var calTopPosition = function calTopPosition(position) {
  return position ? topBottomCssString(position) : 'top: 50%';
};

var calLeftPosition = function calLeftPosition(position) {
  return position ? leftRightCssString(position) : 'left: 50%';
};

var Overlay = _styledComponents2.default.div.withConfig({
  displayName: 'Overlay'
}).withConfig({
  displayName: 'Modal__Overlay',
  componentId: 'sc-1gtbscp-0'
})(['position:fixed;left:0;top:0;height:100%;width:100%;z-index:100;background-color:', ';'], function (props) {
  return props.position ? (0, _transparentize2.default)(1, props.theme.colours.secondary02) : (0, _transparentize2.default)(0.3, props.theme.colours.secondary05);
});

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'Modal__Container',
  componentId: 'sc-1gtbscp-1'
})(['font-family:', ';background-color:', ';position:fixed;', ';', ';transform:', ';max-width:', '%;', ' display:flex;flex-direction:column;padding:', ';box-shadow:0 6px 14px 0 ', ';z-index:100;'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.colours.primary03;
}, function (props) {
  return props.fullScreen ? 'top:0' : calTopPosition(props.position);
}, function (props) {
  return props.fullScreen ? 'left:0' : calLeftPosition(props.position);
}, function (props) {
  return props.position || props.fullScreen ? 'none' : 'translate(-50%, -50%)';
}, function (props) {
  return props.fullScreen ? '100' : '30';
}, function (props) {
  return props.fullScreen ? '\n    height: 95vh;\n    overflow-y: scroll;\n    width: 96vw;\n    ' : '';
}, function (props) {
  return props.fullScreen ? '4vh' : props.theme.layout.standardPadding;
}, function (props) {
  return (0, _transparentize2.default)(0.2, props.theme.colours.secondary05);
});

function Modal(_ref) {
  var children = _ref.children,
      onClickOutside = _ref.onClickOutside,
      position = _ref.position,
      overlay = _ref.overlay,
      fullScreen = _ref.fullScreen;
  return _react2.default.createElement('div', null, overlay ? _react2.default.createElement(Overlay, {
    onClick: onClickOutside,
    position: position
  }) : null, _react2.default.createElement(Container, {
    position: position,
    fullScreen: fullScreen
  }, children));
}

Modal.defaultProps = {
  children: null,
  onClickOutside: function onClickOutside() {},
  position: null,
  overlay: true,
  fullScreen: false
};