// @flow
/* eslint-env browser */
import { put, call } from 'redux-saga/effects';
import evolve from 'ramda/src/evolve';

import { actions, constants } from '../actions/brand';
import { actions as routerActions } from '../actions/router';

import { get, put as update, post } from '../../helpers/http';
import watch from '../../helpers/watch';

import ROUTES, { TO } from '../../constants/routes';
import { API_BASE_URL_BRANDS } from '../../constants/apis';

const up = evolve({
  modules: ms => (ms || []).map(m => ({ name: m.value })),
  pages: ps => (ps || []).map(p => ({ name: p.value })),
});

const down = evolve({
  modules: ms => (ms || []).map(m => ({ value: m.name, label: m.name })),
  pages: ps => (ps || []).map(p => ({ value: p.name, label: p.name })),
});

function* createBrand(token, action) {
  const brand = action.payload;

  try {
    const response = yield call(post, {
      url: API_BASE_URL_BRANDS,
      token,
      data: up(brand),
    });

    yield put(actions.createBrandSuccessful(response.data));
    yield put(routerActions.navigate(TO.SITES(response.data.id)));
  } catch (error) {
    yield put(actions.createBrandFailure(error.response.data));
  }
}

function* updateBrand(token, action) {
  const brand = action.payload;
  try {
    const response = yield call(update, {
      url: `${API_BASE_URL_BRANDS}/${brand.id}`,
      token,
      data: up(brand),
    });

    yield put(actions.updateBrandSuccessful(response.data));
    yield put(routerActions.navigate(ROUTES.BRANDS));
  } catch (error) {
    yield put(actions.updateBrandFailure(error.response.data));
  }
}

export const getIconState = state => state.brand.availableIcons;
export const someValueHasName = (arr, name) => arr.some(x => x.name === name);

function* loadBrands(token) {
  try {
    const response = yield call(get, { url: API_BASE_URL_BRANDS, token });
    const brands = response.data.contents.map(down);
    yield put(actions.loadBrandsSuccessful(brands));
  } catch (error) {
    yield put(actions.loadBrandsFailure({ message: 'Failed to load brands' }));
  }
}

function* loadBrand(token, { payload }) {
  try {
    const { data } = yield call(get, {
      url: `${API_BASE_URL_BRANDS}/${payload}`,
      token,
    });
    yield put(actions.loadBrandSuccessful(down(data)));
  } catch (error) {
    yield put(actions.loadBrandFailure({ message: error.message }));
  }
}

export default [
  watch(constants.CreateBrand, createBrand, true),
  watch(constants.UpdateBrand, updateBrand, true),
  watch(constants.LoadBrand, loadBrand, true),
  watch(constants.LoadBrands, loadBrands, true),
];
