'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DraggableListEditor;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _navicon = require('react-icons/lib/io/navicon');

var _navicon2 = _interopRequireDefault(_navicon);

var _locked = require('react-icons/lib/io/locked');

var _locked2 = _interopRequireDefault(_locked);

var _DragList = require('../DragList');

var _DragList2 = _interopRequireDefault(_DragList);

var _CheckboxSwitch = require('../CheckboxSwitch');

var _CheckboxSwitch2 = _interopRequireDefault(_CheckboxSwitch);

var _itemsHelper = require('./items-helper');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ItemRow = _styledComponents2.default.div.withConfig({
  displayName: 'DraggableListEditor__ItemRow',
  componentId: 'hh7xg-0'
})(['display:flex;align-items:center;justify-content:space-between;']);

var StyledHandle = _styledComponents2.default.div.withConfig({
  displayName: 'DraggableListEditor__StyledHandle',
  componentId: 'hh7xg-1'
})(['cursor:', ';'], function (_ref) {
  var disabled = _ref.disabled;
  return disabled ? 'not-allowed' : 'grab';
});

var ItemLabel = _styledComponents2.default.div.withConfig({
  displayName: 'DraggableListEditor__ItemLabel',
  componentId: 'hh7xg-2'
})(['display:flex;align-items:center;']);

function DraggableListEditor(props) {
  var items = props.value,
      onChange = props.onChange,
      name = props.name;
  return _react2.default.createElement(_DragList2.default, {
    items: items,
    renderItem: function renderItem(RenderHandle, item) {
      return _react2.default.createElement(ItemRow, null, _react2.default.createElement(ItemLabel, null, _react2.default.createElement(RenderHandle, {
        disabled: item.locked
      }), item.label), _react2.default.createElement(_CheckboxSwitch2.default, {
        name: name + '-toggle-' + item.id,
        value: item.active,
        onChange: function (_onChange) {
          function onChange() {
            return _onChange.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange.toString();
          };

          return onChange;
        }(function () {
          return onChange((0, _itemsHelper.updateItem)(item.id, !item.active, items));
        }),
        disabled: item.locked,
        onComponent: item.locked ? _react2.default.createElement(_locked2.default, {
          size: 16,
          color: '#FFFFFF'
        }) : _react2.default.createElement('span', null, 'ON')
      }));
    },
    renderHandle: function renderHandle(_ref2) {
      var disabled = _ref2.disabled;
      return _react2.default.createElement(StyledHandle, {
        disabled: disabled
      }, _react2.default.createElement(_navicon2.default, {
        size: 30
      }));
    },
    onSortEnd: onChange
  });
}