'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

exports.default = PageSeo;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FormGroup = require('./FormGroup');

var _FormGroup2 = _interopRequireDefault(_FormGroup);

var _Input = require('./Input');

var _Input2 = _interopRequireDefault(_Input);

var _TextArea = require('./TextArea');

var _TextArea2 = _interopRequireDefault(_TextArea);

var _Label = require('./Label');

var _Label2 = _interopRequireDefault(_Label);

var _Column = require('./Column');

var _Column2 = _interopRequireDefault(_Column);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'PageSeo__Container',
  componentId: 'qkof1m-0'
})(['font-family:', ';font-size:', ';display:flex;flex-direction:column;'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontSizeContent;
});

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'PageSeo__Title',
  componentId: 'qkof1m-1'
})(['display:flex;justify-content:space-between;']);

var Hint = _styledComponents2.default.span.withConfig({
  displayName: 'PageSeo__Hint',
  componentId: 'qkof1m-2'
})(['font-color:', ';font-size:', ';font-family:', ';display:inline-block;line-height:2;width:\'50%\'};'], function (_ref3) {
  var theme = _ref3.theme;
  return theme.colours.secondary03;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.fontSizeFormLabel;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.fontFamily;
});

function PageSeo(_ref6) {
  var value = _ref6.value,
      onChange = _ref6.onChange;

  var patch = function patch(change) {
    return _extends({}, value, change);
  };

  return _react2.default.createElement(Container, null, _react2.default.createElement(_FormGroup2.default, {
    horizontal: true
  }, _react2.default.createElement(_Label2.default, {
    width: '30%'
  }, 'SEO title'), _react2.default.createElement(_Column2.default, {
    width: '70%'
  }, _react2.default.createElement(_Input2.default, {
    name: 'seo-page-title',
    type: 'text',
    onChange: function (_onChange) {
      function onChange(_x) {
        return _onChange.apply(this, arguments);
      }

      onChange.toString = function () {
        return _onChange.toString();
      };

      return onChange;
    }(function (e) {
      return onChange(patch({
        title: e.target.value
      }));
    }),
    value: value.title,
    placeholder: 'my-page',
    prefix: null
  }))), _react2.default.createElement(_FormGroup2.default, null, _react2.default.createElement(Title, null, _react2.default.createElement(_Label2.default, {
    width: '50%'
  }, 'SEO description'), _react2.default.createElement(Hint, null, 'Max. 200 characters')), _react2.default.createElement(_Column2.default, null, _react2.default.createElement(_TextArea2.default, {
    value: value.description,
    onChange: function (_onChange2) {
      function onChange(_x2) {
        return _onChange2.apply(this, arguments);
      }

      onChange.toString = function () {
        return _onChange2.toString();
      };

      return onChange;
    }(function (description) {
      return onChange(patch({
        description: description
      }));
    }),
    placeholder: 'Type desired text...',
    rows: 8
  }))));
}