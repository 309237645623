// @flow
import React from 'react';
import { connect } from 'react-redux';
import Sites from '../components/Sites';
import { actions } from '../state/actions/site';
import { actions as brandActions } from '../state/actions/brand';
import { actions as routerActions } from '../state/actions/router';

const getBrandId = props => props.match.params.brandId;

const mapStateToProps = ({ site, brand }) => ({
  sites: site.sites,
  brands: brand.brands,
});

const mapDispatchToProps = dispatch => ({
  loadSites: brandId => dispatch(actions.loadSites(brandId)),
  loadBrand: brandId => dispatch(brandActions.loadBrand(brandId)),
  onNavigate: route => dispatch(routerActions.navigate(route)),
});

class SitesWithLifecycle extends React.Component<*, *> {
  componentDidMount() {
    const brandId = getBrandId(this.props);

    if (brandId) {
      this.props.loadSites(brandId);
      this.props.loadBrand(brandId);
    }
  }

  render() {
    const brandId = getBrandId(this.props);
    const brand = this.props.brands[brandId];

    return (
      <div>
        <Sites brand={brand} {...this.props} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SitesWithLifecycle);
