// @flow
import type { Brand } from '../reducers/brand';
import type { Site, PartialSite } from '../reducers/site';
import type { Error } from '../types/error';

type BrandId = $PropertyType<Brand, 'id'>;

const CreateSite: 'CREATE SITE' = 'CREATE SITE';
export type CreateSiteAction = {
  type: typeof CreateSite,
  payload: { site: PartialSite, brandId: BrandId },
};
const createSite = (brandId: BrandId, site: PartialSite): CreateSiteAction => ({
  type: CreateSite,
  payload: { brandId, site },
});

const CreateSiteSuccessful: 'CREATE SITE SUCCESS' = 'CREATE SITE SUCCESS';
export type CreateSiteSuccessfulAction = {
  type: typeof CreateSiteSuccessful,
  payload: Site,
};
const createSiteSuccessful = (site: Site): CreateSiteSuccessfulAction => ({
  type: CreateSiteSuccessful,
  payload: site,
});

const CreateSiteFailure: 'CREATE SITE FAILURE' = 'CREATE SITE FAILURE';
export type CreateSiteFailureAction = {
  type: typeof CreateSiteFailure,
  payload: Error,
};
const createSiteFailure = (error: Error): CreateSiteFailureAction => ({
  type: CreateSiteFailure,
  payload: error,
});

const LoadSites: 'LOAD SITES' = 'LOAD SITES';
export type LoadSitesAction = { type: typeof LoadSites, payload: string };
const loadSites = (brandId: string): LoadSitesAction => ({
  type: LoadSites,
  payload: brandId,
});

const LoadSitesSuccessful: 'SITES LOAD SUCCESS' = 'SITES LOAD SUCCESS';
export type LoadSitesSuccessfulAction = {
  type: typeof LoadSitesSuccessful,
  payload: Site[],
};
const loadSitesSuccessful = (sites: Site[]): LoadSitesSuccessfulAction => ({
  type: LoadSitesSuccessful,
  payload: sites,
});

const LoadSitesFailure: 'SITES LOAD FAILURE' = 'SITES LOAD FAILURE';
export type LoadSitesFailureAction = {
  type: typeof LoadSitesFailure,
  payload: Error,
};
const loadSitesFailure = (error: Error): LoadSitesFailureAction => ({
  type: LoadSitesFailure,
  payload: error,
});

const LoadSite: 'LOAD SITE' = 'LOAD SITE';
export type LoadSiteAction = {
  type: typeof LoadSite,
  payload: { brandId: string, siteId: string },
};
const loadSite = (brandId: string, siteId: string): LoadSiteAction => ({
  type: LoadSite,
  payload: { brandId, siteId },
});

const LoadSiteSuccess: 'LOAD SITE SUCCESS' = 'LOAD SITE SUCCESS';
export type LoadSiteSuccessAction = {
  type: typeof LoadSiteSuccess,
  payload: Site,
};
const loadSiteSuccess = (site: Site): LoadSiteSuccessAction => ({
  type: LoadSiteSuccess,
  payload: site,
});

const LoadSiteFailure: 'LOAD SITE FAILURE' = 'LOAD SITE FAILURE';
export type LoadSiteFailureAction = {
  type: typeof LoadSiteFailure,
  payload: Error,
};
const loadSiteFailure = (error: Error): LoadSiteFailureAction => ({
  type: LoadSiteFailure,
  payload: error,
});

const UpdateSite: 'UPDATE SITE' = 'UPDATE SITE';
export type UpdateSiteAction = {
  type: typeof UpdateSite,
  payload: { site: Site, brandId: BrandId },
};
const updateSite = (brandId: BrandId, site: Site): UpdateSiteAction => ({
  type: UpdateSite,
  payload: { brandId, site },
});

const UpdateSiteSuccessful: 'UPDATE SITE SUCCESS' = 'UPDATE SITE SUCCESS';
export type UpdateSiteSuccessfulAction = {
  type: typeof UpdateSiteSuccessful,
  payload: Site,
};
const updateSiteSuccessful = (site: Site): UpdateSiteSuccessfulAction => ({
  type: UpdateSiteSuccessful,
  payload: site,
});

const UpdateSiteFailure: 'UPDATE SITE FAILURE' = 'UPDATE SITE FAILURE';
export type UpdateSiteFailureAction = {
  type: typeof UpdateSiteFailure,
  payload: Error,
};
const updateSiteFailure = (error: Error): UpdateSiteFailureAction => ({
  type: UpdateSiteFailure,
  payload: error,
});

export const actions = {
  createSite,
  createSiteFailure,
  createSiteSuccessful,
  loadSite,
  loadSiteFailure,
  loadSites,
  loadSitesFailure,
  loadSitesSuccessful,
  loadSiteSuccess,
  updateSite,
  updateSiteFailure,
  updateSiteSuccessful,
};

export const constants = {
  CreateSite,
  CreateSiteFailure,
  CreateSiteSuccessful,
  LoadSite,
  LoadSiteFailure,
  LoadSites,
  LoadSitesFailure,
  LoadSitesSuccessful,
  LoadSiteSuccess,
  UpdateSite,
  UpdateSiteFailure,
  UpdateSiteSuccessful,
};

export type Action =
  | CreateSiteAction
  | CreateSiteFailureAction
  | CreateSiteSuccessfulAction
  | LoadSiteAction
  | LoadSiteFailureAction
  | LoadSitesAction
  | LoadSitesFailureAction
  | LoadSitesSuccessfulAction
  | LoadSiteSuccessAction
  | UpdateSiteAction
  | UpdateSiteFailureAction
  | UpdateSiteSuccessfulAction;
