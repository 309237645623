'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ComponentList;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _TextAlignment = require('./TextAlignment');

var _TextAlignment2 = _interopRequireDefault(_TextAlignment);

var _DeleteButton = require('./DeleteButton');

var _DeleteButton2 = _interopRequireDefault(_DeleteButton);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledTable = _styledComponents2.default.table.withConfig({
  displayName: 'StyledTable'
}).withConfig({
  displayName: 'ComponentList__StyledTable',
  componentId: 'sc-1hjgtln-0'
})(['width:100%;font-family:', ';font-size:', ';'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontSizeContent;
});

var StyledTH = _styledComponents2.default.th.withConfig({
  displayName: 'StyledTH'
}).withConfig({
  displayName: 'ComponentList__StyledTH',
  componentId: 'sc-1hjgtln-1'
})(['color:', ';text-align:left;width:33%;padding-bottom:10px;&:last-child{text-align:center;}'], function (props) {
  return props.theme.colours.secondary03;
});

var StyledTD = _styledComponents2.default.td.withConfig({
  displayName: 'StyledTD'
}).withConfig({
  displayName: 'ComponentList__StyledTD',
  componentId: 'sc-1hjgtln-2'
})(['&:first-child{line-height:40px;}&:last-child{text-align:center;width:33%;max-width:33%;font-size:', ';}'], function (props) {
  return props.theme.fontSizeHeader;
});

function ComponentList(_ref) {
  var selectedComponents = _ref.selectedComponents,
      onChange = _ref.onChange,
      onClose = _ref.onClose,
      onOpen = _ref.onOpen,
      onDelete = _ref.onDelete;
  return _react2.default.createElement(StyledTable, null, _react2.default.createElement('thead', null, _react2.default.createElement('tr', null, _react2.default.createElement(StyledTH, null, 'Added...'), _react2.default.createElement(StyledTH, null, 'Align'), _react2.default.createElement(StyledTH, null, 'Delete'))), _react2.default.createElement('tbody', null, selectedComponents.map(function (_ref2, index) {
    var componentName = _ref2.componentName,
        value = _ref2.value,
        open = _ref2.open;
    return _react2.default.createElement('tr', {
      key: '' + componentName + (index + 1)
    }, _react2.default.createElement(StyledTD, null, componentName), _react2.default.createElement(StyledTD, null, _react2.default.createElement(_TextAlignment2.default, {
      name: '' + componentName,
      value: value,
      open: open,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (newValue) {
        return onChange(index, newValue);
      }),
      onClose: function (_onClose) {
        function onClose() {
          return _onClose.apply(this, arguments);
        }

        onClose.toString = function () {
          return _onClose.toString();
        };

        return onClose;
      }(function () {
        return onClose(index);
      }),
      onOpen: function (_onOpen) {
        function onOpen() {
          return _onOpen.apply(this, arguments);
        }

        onOpen.toString = function () {
          return _onOpen.toString();
        };

        return onOpen;
      }(function () {
        return onOpen(index);
      })
    })), _react2.default.createElement(StyledTD, null, _react2.default.createElement(_DeleteButton2.default, {
      onClick: function onClick() {
        return onDelete(index);
      }
    })));
  })));
}