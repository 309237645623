// @flow
import React from 'react';
import styled from 'styled-components';
import { Banner, Button, Box, BoxList, Link } from 'cms-ui';

import { type Brand } from '../state/reducers/brand';
import { type Site } from '../state/reducers/site';
import { TO } from '../constants/routes';

const SiteName = styled.div`
  flex: 1 0 0%;
`;

const BannerItems = styled.div`
  display: flex;
  flex: 1 0 0%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  onNavigate: string => void,
  sites: { [id: $PropertyType<Site, 'id'>]: Site },
  brand: Brand,
};

export default function Sites({ sites, brand, onNavigate: navigate }: Props) {
  if (!brand) return null;

  const renderSite = (site: any) => (
    <Box key={site.id}>
      <SiteName>{site.name}</SiteName>
      <Link
        href={TO.EDIT_SITE(brand.id, site.id)}
        onClick={e => {
          e.preventDefault();
          navigate(TO.EDIT_SITE(brand.id, site.id));
        }}
      >
        Edit
      </Link>
    </Box>
  );

  return (
    <div>
      <Banner>
        <BannerItems>
          {brand.name} - Site list
          <Button onClick={() => navigate(TO.CREATE_SITE(brand.id))}>
            CREATE SITE
          </Button>
        </BannerItems>
      </Banner>
      <BoxList>{Object.values(sites).map(renderSite)}</BoxList>
    </div>
  );
}
