// @flow
import React from 'react';
import { Field } from 'redux-form';
import { CheckboxGroup, Fieldset, SectionHeading } from 'cms-ui';
import { requiredCheckboxGroup } from '../helpers/validation';

type Props = {
  modules: { value: string, label: string }[],
};

export default function PagesAndModules({ modules }: Props) {
  return (
    <Fieldset>
      <SectionHeading>MODULES</SectionHeading>
      <Field
        component={CheckboxGroup}
        validate={requiredCheckboxGroup}
        name="modules"
        getLabelFromOption={option => option.label}
        getValueFromOption={option => option.value}
        options={modules}
        hasSelectAll
      />
    </Fieldset>
  );
}
