'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CheckboxGroup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ = require('.');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
} // This is a custom component for Redux Form Field component
// and is used by passing it to the Field's component prop
// input and meta are props passed from the Field component


function CheckboxGroup(_ref) {
  var input = _ref.input,
      options = _ref.options,
      hasSelectAll = _ref.hasSelectAll,
      getLabelFromOption = _ref.getLabelFromOption,
      getValueFromOption = _ref.getValueFromOption,
      _ref$meta = _ref.meta,
      error = _ref$meta.error,
      dirty = _ref$meta.dirty;
  var gV = input.value,
      name = input.name;
  var groupValue = gV || [];

  var changeOptionChecked = function changeOptionChecked(option) {
    return function (checked) {
      return input.onChange(checked ? [].concat(_toConsumableArray(groupValue), [option]) : groupValue.filter(function (o) {
        return getValueFromOption(o) !== getValueFromOption(option);
      }));
    };
  };

  return _react2.default.createElement('div', null, hasSelectAll && _react2.default.createElement(_.CheckboxGroupSelectAll, {
    input: input,
    options: options
  }), options.map(function (option) {
    var optionValue = getValueFromOption(option);
    return _react2.default.createElement(_.Checkbox, {
      key: optionValue,
      name: name + '[' + optionValue + ']',
      checked: groupValue.some(function (v) {
        return getValueFromOption(v) === optionValue;
      }),
      onChange: changeOptionChecked(option),
      label: getLabelFromOption(option)
    });
  }), dirty && error && _react2.default.createElement(_.InlineError, {
    error: dirty && error
  }, error));
}

CheckboxGroup.defaultProps = {
  options: [],
  getLabelFromOption: function getLabelFromOption(i) {
    return i;
  },
  getValueFromOption: function getValueFromOption(i) {
    return i;
  }
};