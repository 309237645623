'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Separator;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Separator__Container',
  componentId: 'sc-14h2lqp-0'
})(['color:', ';display:flex;width:', ';font-size:26px;font-weight:600;'], function (props) {
  return props.theme.colours.lowlight;
}, function (_ref) {
  var width = _ref.width;
  return width || '100%';
});

var Rule = _styledComponents2.default.hr.withConfig({
  displayName: 'Separator__Rule',
  componentId: 'sc-14h2lqp-1'
})(['flex:1 0 0%;color:', ';height:0;'], function (props) {
  return props.theme.colours.lowlight;
});

var LabelWrapper = _styledComponents2.default.span.withConfig({
  displayName: 'Separator__LabelWrapper',
  componentId: 'sc-14h2lqp-2'
})(['margin:0 ', ';'], function (props) {
  return props.theme.layout.standardPadding;
});

function Separator(_ref2) {
  var label = _ref2.label,
      width = _ref2.width;
  return _react2.default.createElement(Container, {
    width: width
  }, _react2.default.createElement(Rule, null), label ? _react2.default.createElement(LabelWrapper, null, label) : _react2.default.createElement(Rule, null), _react2.default.createElement(Rule, null));
}

Separator.defaultProps = {
  label: null,
  width: null
};