'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TextArea;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledTextArea = _styledComponents2.default.textarea.withConfig({
  displayName: 'StyledTextArea'
}).withConfig({
  displayName: 'TextArea__StyledTextArea',
  componentId: 'sc-1my33sj-0'
})(['box-sizing:border-box;width:100%;font-family:', ';font-size:', ';color:', ';border-color:', ';resize:vertical;&:focus{border:1px solid ', ';}'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontSizeFormControls;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colours.secondary06;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colours.secondary03;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colours.primary01;
});

function TextArea(_ref6) {
  var value = _ref6.value,
      onChange = _ref6.onChange,
      placeholder = _ref6.placeholder,
      rows = _ref6.rows;
  return _react2.default.createElement(StyledTextArea, {
    onChange: function (_onChange) {
      function onChange(_x) {
        return _onChange.apply(this, arguments);
      }

      onChange.toString = function () {
        return _onChange.toString();
      };

      return onChange;
    }(function (event) {
      return onChange(event.target.value);
    }),
    placeholder: placeholder,
    rows: rows,
    value: value
  });
}