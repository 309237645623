'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DragList;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactSortableHoc = require('react-sortable-hoc');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var handle = function handle(renderFn) {
  return (0, _reactSortableHoc.SortableHandle)(renderFn);
};

var SortableList = (0, _reactSortableHoc.SortableContainer)(function (_ref) {
  var items = _ref.items,
      renderItem = _ref.renderItem,
      renderHandle = _ref.renderHandle,
      keyFn = _ref.keyFn;
  return _react2.default.createElement('div', null, items.map(function (value, index) {
    return _react2.default.createElement(SortableItem, {
      key: keyFn(value),
      index: index,
      value: value,
      renderItem: renderItem,
      renderHandle: renderHandle,
      item: value,
      disabled: value.locked
    });
  }));
});
var SortableItem = (0, _reactSortableHoc.SortableElement)(function (_ref2) {
  var renderItem = _ref2.renderItem,
      renderHandle = _ref2.renderHandle,
      item = _ref2.item;
  return renderItem(renderHandle, item);
});

function DragList(_ref3) {
  var items = _ref3.items,
      onSortEnd = _ref3.onSortEnd,
      renderItem = _ref3.renderItem,
      renderHandle = _ref3.renderHandle,
      keyFn = _ref3.keyFn;
  var handleRenderer = handle(renderHandle);
  return _react2.default.createElement(SortableList, {
    keyFn: keyFn,
    items: items,
    onSortEnd: function (_onSortEnd) {
      function onSortEnd(_x) {
        return _onSortEnd.apply(this, arguments);
      }

      onSortEnd.toString = function () {
        return _onSortEnd.toString();
      };

      return onSortEnd;
    }(function (_ref4) {
      var oldIndex = _ref4.oldIndex,
          newIndex = _ref4.newIndex;
      return onSortEnd((0, _reactSortableHoc.arrayMove)(items, oldIndex, newIndex));
    }),
    renderItem: renderItem,
    renderHandle: handleRenderer,
    useDragHandle: true
  });
}

DragList.defaultProps = {
  items: [],
  onChange: function onChange() {},
  onSort: function onSort() {},
  keyFn: function keyFn(_ref5) {
    var id = _ref5.id;
    return id;
  }
};