'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

exports.default = PageDetails;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Select = require('./Select');

var _Select2 = _interopRequireDefault(_Select);

var _Input = require('./Input');

var _Input2 = _interopRequireDefault(_Input);

var _Label = require('./Label');

var _Label2 = _interopRequireDefault(_Label);

var _Column = require('./Column');

var _Column2 = _interopRequireDefault(_Column);

var _FormGroup = require('./FormGroup');

var _FormGroup2 = _interopRequireDefault(_FormGroup);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  type: 'Container'
}).withConfig({
  displayName: 'PageDetails__Container',
  componentId: 'sc-1gbk6q8-0'
})(['font-family:', ';font-size:', ';display:flex;flex-direction:column;'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontSizeContent;
});

var ReadonlyPageType = _styledComponents2.default.span.withConfig({
  displayName: 'PageDetails__ReadonlyPageType',
  componentId: 'sc-1gbk6q8-1'
})(['line-height:30px;']);

function PageDetails(_ref3) {
  var pageTemplates = _ref3.pageTemplates,
      value = _ref3.value,
      onChange = _ref3.onChange,
      canChangeType = _ref3.canChangeType;

  var onChangePageName = function onChangePageName(pageName) {
    return onChange(_extends({}, value, {
      pageName: pageName
    }));
  };

  var onChangePageTemplate = function onChangePageTemplate(pageTemplateId) {
    return onChange(_extends({}, value, {
      pageTemplateId: pageTemplateId
    }));
  };

  var onChangeRelativePath = function onChangeRelativePath(relativePath) {
    return onChange(_extends({}, value, {
      relativePath: '/' + relativePath
    }));
  };

  var relativePath = value.relativePath ? value.relativePath.replace(/^\//, '') : '';
  var pageTemplate = pageTemplates.find(function (p) {
    return p.value === value.pageTemplateId;
  });
  return _react2.default.createElement(Container, null, _react2.default.createElement(_FormGroup2.default, {
    horizontal: true
  }, _react2.default.createElement(_Label2.default, {
    width: '30%'
  }, 'Page name'), _react2.default.createElement(_Column2.default, {
    width: '70%'
  }, _react2.default.createElement(_Input2.default, {
    name: 'pageName',
    value: value.pageName,
    type: 'text',
    onChange: function onChange(e) {
      return onChangePageName(e.target.value);
    },
    placeholder: 'Type page name...',
    prefix: ''
  }))), _react2.default.createElement(_FormGroup2.default, {
    horizontal: true
  }, _react2.default.createElement(_Label2.default, {
    width: '30%'
  }, 'Page type'), _react2.default.createElement(_Column2.default, {
    width: '70%'
  }, canChangeType ? _react2.default.createElement(_Select2.default, {
    placeholder: 'Select page type',
    name: 'pageTemplateId',
    onChange: function onChange(e) {
      return onChangePageTemplate(e.target.value);
    },
    options: pageTemplates
  }) : pageTemplate && _react2.default.createElement(ReadonlyPageType, null, pageTemplate.label))), _react2.default.createElement(_FormGroup2.default, {
    horizontal: true
  }, _react2.default.createElement(_Label2.default, {
    width: '25%'
  }, 'URL'), _react2.default.createElement(_Column2.default, {
    width: '75%'
  }, value.required ? _react2.default.createElement(ReadonlyPageType, null, 'name.com/' + relativePath) : _react2.default.createElement(_Input2.default, {
    name: 'relativePath',
    value: relativePath,
    type: 'text',
    onChange: function onChange(e) {
      return onChangeRelativePath(e.target.value);
    },
    prefix: 'name.com/',
    placeholder: 'my-page'
  }))));
}