'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Modal = require('./Modal');

var _Modal2 = _interopRequireDefault(_Modal);

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}
/* eslint-env browser */


var position = function position(x, y) {
  return {
    x: x,
    y: y,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight
  };
};

var ButtonWithConfirm = function (_React$Component) {
  _inherits(ButtonWithConfirm, _React$Component);

  function ButtonWithConfirm() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ButtonWithConfirm);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ButtonWithConfirm.__proto__ || Object.getPrototypeOf(ButtonWithConfirm)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      showConfirm: false,
      position: position(0, 0)
    }, _this.showConfirmDialogueAtPosition = function (_ref2) {
      var clientX = _ref2.clientX,
          clientY = _ref2.clientY;

      _this.setState({
        position: position(clientX, clientY),
        showConfirm: true
      });
    }, _this.hideConfirmDialogue = function () {
      return _this.setState({
        showConfirm: false
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ButtonWithConfirm, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          buttonText = _props.buttonText,
          renderModalContents = _props.renderModalContents,
          buttonProps = _props.buttonProps;
      return _react2.default.createElement('div', null, _react2.default.createElement(_Button2.default, _extends({}, buttonProps, {
        onClick: this.showConfirmDialogueAtPosition
      }), buttonText), this.state.showConfirm ? _react2.default.createElement(_Modal2.default, {
        position: this.state.position,
        overlay: true,
        onClickOutside: this.hideConfirmDialogue
      }, renderModalContents(this.hideConfirmDialogue)) : null);
    }
  }]);

  return ButtonWithConfirm;
}(_react2.default.Component);

exports.default = ButtonWithConfirm;
ButtonWithConfirm.defaultProps = {
  buttonProps: {
    small: true,
    fullWidth: true,
    danger: true,
    textTransform: 'uppercase'
  }
};