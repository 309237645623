'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SectionMenuBack;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _transparentize = require('polished/lib/color/transparentize');

var _transparentize2 = _interopRequireDefault(_transparentize);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SectionMenuBack__Wrapper',
  componentId: 'sc-1cjx5dh-0'
})(['flex:1 0 0%;display:flex;justify-content:center;']);

var Body = _styledComponents2.default.div.withConfig({
  displayName: 'SectionMenuBack__Body',
  componentId: 'sc-1cjx5dh-1'
})(['font-family:', ';color:', ';display:flex;font-size:', ';justify-content:space-between;align-items:center;box-sizing:border-box;height:32px;padding:', ';background-color:#33a8ef;'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.colours.secondary01;
}, function (props) {
  return props.theme.fontSizeContent;
}, function (props) {
  return props.theme.layout.standardPadding;
});

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'SectionMenuBack__Title',
  componentId: 'sc-1cjx5dh-2'
})(['color:', ';font-size:', ';line-height:17px;font-weight:600;margin-left:', ';'], function (props) {
  return (0, _transparentize2.default)(0.5, props.theme.colours.primary03);
}, function (props) {
  return props.theme.fontSizeFormControls;
}, function (props) {
  return props.theme.layout.halfPadding;
});

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'SectionMenuBack__Icon',
  componentId: 'sc-1cjx5dh-3'
})(['height:12px;margin-left:5px;cursor:pointer;']);

function SectionMenuBack(_ref) {
  var children = _ref.children,
      onClick = _ref.onClick,
      onPlusClick = _ref.onPlusClick,
      withLinksIcon = _ref.withLinksIcon;
  return _react2.default.createElement(Body, null, _react2.default.createElement(Icon, {
    src: 'https://res.cloudinary.com/motortrak/image/upload/v1572449966/editor/chevron-left.svg',
    onClick: onClick
  }), _react2.default.createElement(Wrapper, null, _react2.default.createElement(Title, null, children)), withLinksIcon && _react2.default.createElement(Icon, {
    src: 'https://res.cloudinary.com/motortrak/image/upload/v1564992732/editor/links-icon.svg'
  }), onPlusClick && _react2.default.createElement(Icon, {
    src: 'https://res.cloudinary.com/motortrak/image/upload/v1571824537/editor/white-plus.png',
    onClick: onPlusClick
  }));
}