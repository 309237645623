'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ComponentSelector;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ComponentList = require('./ComponentList');

var _ComponentList2 = _interopRequireDefault(_ComponentList);

var _Select = require('./Select');

var _Select2 = _interopRequireDefault(_Select);

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledLabel = _styledComponents2.default.label.withConfig({
  displayName: 'StyledLabel'
}).withConfig({
  displayName: 'ComponentSelector__StyledLabel',
  componentId: 'sc-1vhudyk-0'
})(['color:', ';font-family:', ';font-weight:600;font-size:', ';'], function (props) {
  return props.theme.colours.secondary06;
}, function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontSizeContent;
});

var StyledSelect = _styledComponents2.default.div.withConfig({
  displayName: 'StyledSelect'
}).withConfig({
  displayName: 'ComponentSelector__StyledSelect',
  componentId: 'sc-1vhudyk-1'
})(['& select{display:inline;}']);

var StyledButton = (0, _styledComponents2.default)(_Button2.default).withConfig({
  displayName: 'StyledButton'
}).withConfig({
  displayName: 'ComponentSelector__StyledButton',
  componentId: 'sc-1vhudyk-2'
})(['margin-left:10px;height:25px;padding:5px 10px;']);

function ComponentSelector(_ref) {
  var options = _ref.options,
      onSelectChange = _ref.onSelectChange,
      onAdd = _ref.onAdd,
      selectedComponents = _ref.selectedComponents,
      onChange = _ref.onChange,
      onClose = _ref.onClose,
      onOpen = _ref.onOpen,
      onDelete = _ref.onDelete;
  return _react2.default.createElement('div', null, _react2.default.createElement(StyledLabel, null, 'Components'), _react2.default.createElement(StyledSelect, null, _react2.default.createElement(_Select2.default, {
    name: 'componentSelectList',
    options: options || [],
    selectedValue: options[0].value || '',
    onChange: function onChange(event) {
      return onSelectChange(event.target.value);
    }
  }), _react2.default.createElement(StyledButton, {
    primary: true,
    onClick: onAdd
  }, 'ADD')), _react2.default.createElement(_ComponentList2.default, {
    selectedComponents: selectedComponents,
    onChange: onChange,
    onClose: onClose,
    onOpen: onOpen,
    onDelete: onDelete
  }));
}