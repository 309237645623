'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _trashB = require('react-icons/lib/io/trash-b');

var _trashB2 = _interopRequireDefault(_trashB);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var DeleteButton = (0, _styledComponents2.default)(_trashB2.default).withConfig({
  displayName: 'DeleteButton'
}).withConfig({
  displayName: 'DeleteButton',
  componentId: 'sh1bf1-0'
})(['color:', ';', ' border-radius:50px;&:hover{cursor:pointer;background:', ';}'], function (props) {
  return props.theme.colours.primary01;
}, function (_ref) {
  var small = _ref.small;
  return small ? 'margin-left: 6px;' : 'padding: 6px;';
}, function (props) {
  return props.theme.colours.disabled02;
});
exports.default = DeleteButton;