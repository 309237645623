// @flow
import { eventChannel } from 'redux-saga';
import { call, takeEvery, put } from 'redux-saga/effects';

import history from '../../history';
import { actions, constants } from '../actions/router';
import watch from '../../helpers/watch';

function* navigate({ payload: route }): Iterator<*> {
  yield call(history.push, route);
}

function createNavigationWatcher() {
  return eventChannel(emitter =>
    history.listen(location => {
      emitter(actions.navigateSuccess(location));
    }),
  );
}

function* watchNavigations(): Iterator<*> {
  yield put(actions.navigateSuccess(history.location));
  const navigationChannel = yield call(createNavigationWatcher);
  yield takeEvery(navigationChannel, put);
}

export default [watch(constants.Navigate, navigate), watchNavigations];
