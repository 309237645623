// @flow
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import authReducer, { type State as AuthState } from './reducers/auth';
import brandReducer, { type State as BrandState } from './reducers/brand';
import siteReducer, { type State as SiteState } from './reducers/site';
import languageReducer, {
  type State as LanguageState,
} from './reducers/language';
import routerReducer, { type State as RouterState } from './reducers/router';

export type State = {
  auth: AuthState,
  brand: BrandState,
  site: SiteState,
  language: LanguageState,
  router: RouterState,
};

export default combineReducers({
  auth: authReducer,
  brand: brandReducer,
  form: formReducer,
  site: siteReducer,
  language: languageReducer,
  router: routerReducer,
});
