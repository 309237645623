// @flow
import React from 'react';
import { connect } from 'react-redux';
import Brands from '../components/Brands';
import { actions as brandActions } from '../state/actions/brand';
import { actions as routerActions } from '../state/actions/router';
import allErrors from '../state/selectors/errors';

const mapStateToProps = state => ({
  brands: state.brand.brands,
  errors: allErrors(state),
});

const mapDispatchToProps = dispatch => ({
  loadBrands: () => dispatch(brandActions.loadBrands()),
  onNavigate: route => dispatch(routerActions.navigate(route)),
});

class BrandsWithLifeCycle extends React.Component<*, *> {
  componentDidMount = () => {
    this.props.loadBrands();
  };

  render() {
    return <Brands {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrandsWithLifeCycle);
