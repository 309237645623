'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = IconBox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledIconBox = _styledComponents2.default.div.withConfig({
  displayName: 'IconBox'
}).withConfig({
  displayName: 'IconBox__StyledIconBox',
  componentId: 'sc-1jeqtn7-0'
})(['display:flex;margin-left:', ';align-items:center;justify-content:center;font-weight:600;text-align:center;'], function (props) {
  return props.theme.layout.standardPadding;
});

function IconBox(props) {
  return _react2.default.createElement(StyledIconBox, props);
}