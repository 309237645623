// @flow
import React from 'react';
import { reduxForm } from 'redux-form';
import { Banner, Button, FormContainer, Form as StyledForm } from 'cms-ui';

import type { Brand as BrandType } from '../state/reducers/brand';
import type { Language } from '../state/reducers/language';
import type { Error } from '../state/types/error';

import SelectPages from './SelectPages';
import SelectModules from './SelectModules';
import GeneralDetails from './GeneralDetails';
import Users from './Users';
import ErrorMessageWithScrollTo from './ErrorMessageWithScrollTo';

type FormProps = {
  languages: Language[],
  handleSubmit: Function,
  addUser: Function,
  removeUser: Function,
  invalid: Boolean,
  initialValues: ?BrandType,
};

const pages = {
  content: {
    title: 'Content Page',
  },
};

const modulesUsersAreAllowedToSelect = [
  {
    value: 'Band',
    label: 'Band',
  },
];

const Form = ({
  languages,
  handleSubmit,
  invalid,
  initialValues,
  addUser,
  removeUser,
}: FormProps) => (
  <FormContainer width="40%">
    <StyledForm onSubmit={handleSubmit}>
      <GeneralDetails languages={languages} />
      <SelectPages pages={pages} />
      <SelectModules modules={modulesUsersAreAllowedToSelect} />
      <Users addUser={addUser} removeUser={removeUser} />
      <Button type="submit" disabled={invalid}>
        {initialValues ? 'EDIT BRAND' : 'ADD NEW BRAND'}
      </Button>
    </StyledForm>
  </FormContainer>
);

// This form name is used to detect logo and font changes in effects/brand.js.
// If changing it, please make sure to update it there also.
const BrandForm = reduxForm({
  form: 'brandForm',
  enableReinitialize: true,
})(Form);

type BrandProps = {
  languages: string[],
  createBrand: Function,
  updateBrand: Function,
  loadBrand: Function,
  brand: BrandType,
  addUser: Function,
  removeUser: Function,
  error: ?Error,
};

export default function Brand({
  createBrand,
  updateBrand,
  addUser,
  removeUser,
  languages,
  brand,
  error,
}: BrandProps) {
  return (
    <div>
      <Banner>{brand ? 'Edit brand' : 'Create new brand'}</Banner>
      {error && <ErrorMessageWithScrollTo error={error} />}
      <BrandForm
        initialValues={brand}
        onSubmit={brand ? updateBrand : createBrand}
        languages={languages}
        addUser={addUser}
        removeUser={removeUser}
      />
    </div>
  );
}
