'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SectionMenuTitle;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FlexWrapper = require('./FlexWrapper');

var _FlexWrapper2 = _interopRequireDefault(_FlexWrapper);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'Title'
}).withConfig({
  displayName: 'SectionMenuTitle__Title',
  componentId: 'sc-11axkzv-0'
})(['font-family:', ';color:', ';display:flex;font-size:14px;line-height:17px;align-items:center;box-sizing:border-box;height:32px;font-weight:600;padding:', ';background:linear-gradient( 180deg,#009fef 0%,', ' 100% );'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.colours.secondary01;
}, function (props) {
  return props.theme.layout.standardPadding;
}, function (props) {
  return props.theme.colours.primary01;
});

function SectionMenuTitle(_ref) {
  var children = _ref.children;
  return _react2.default.createElement(Title, null, _react2.default.createElement(_FlexWrapper2.default, null, children));
}