'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Accordion;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _chevronDown = require('react-icons/lib/io/chevron-down');

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require('react-icons/lib/io/chevron-up');

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _CheckboxSwitch = require('./CheckboxSwitch');

var _CheckboxSwitch2 = _interopRequireDefault(_CheckboxSwitch);

var _IconBox = require('./IconBox');

var _IconBox2 = _interopRequireDefault(_IconBox);

var _FlexWrapper = require('./FlexWrapper');

var _FlexWrapper2 = _interopRequireDefault(_FlexWrapper);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'Accordion__Container',
  componentId: 'sc-1e1z9d8-0'
})(['font-family:', ';display:flex;flex-direction:column;padding:0;overflow:', ';'], function (props) {
  return props.theme.fontFamily;
}, function (_ref) {
  var open = _ref.open;
  return open ? '' : 'hidden';
});

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'Title'
}).withConfig({
  displayName: 'Accordion__Title',
  componentId: 'sc-1e1z9d8-1'
})(['color:', ';display:flex;font-size:14px;justify-content:space-between;align-items:center;height:14px;font-weight:600;padding:', ';background-color:', ';box-shadow:0 0 20px 0 rgba(0,0,0,0.1);cursor:', ';'], function (props) {
  return props.disabled ? props.theme.colours.secondary02 : props.theme.colours.secondary03;
}, function (props) {
  return props.theme.layout.standardPadding;
}, function (props) {
  return props.theme.colours.primary03;
}, function (props) {
  return props.disabled_ie11 ? 'not-allowed' : 'pointer';
});

var IsOpenIcon = (0, _styledComponents2.default)(_chevronUp2.default).withConfig({
  displayName: 'IsOpenIcon'
}).withConfig({
  displayName: 'Accordion__IsOpenIcon',
  componentId: 'sc-1e1z9d8-2'
})(['']);
var IsClosedIcon = (0, _styledComponents2.default)(_chevronDown2.default).withConfig({
  displayName: 'IsClosedIcon'
}).withConfig({
  displayName: 'Accordion__IsClosedIcon',
  componentId: 'sc-1e1z9d8-3'
})(['']);

var ContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ContentContainer'
}).withConfig({
  displayName: 'Accordion__ContentContainer',
  componentId: 'sc-1e1z9d8-4'
})(['max-height:', ';'], function (_ref2) {
  var visible = _ref2.visible;
  return visible ? 'auto' : '0';
});

var ContentMargin = _styledComponents2.default.div.withConfig({
  displayName: 'Accordion__ContentMargin',
  componentId: 'sc-1e1z9d8-5'
})(['border-left:1px solid ', ';padding:', ';margin:', ' 0;'], function (props) {
  return props.theme.colours.secondary02;
}, function (props) {
  return props.theme.layout.halfPadding + ' 10px';
}, function (props) {
  return props.theme.layout.standardPadding;
});

function Accordion(_ref3) {
  var titleContents = _ref3.titleContents,
      bodyContents = _ref3.bodyContents,
      open = _ref3.open,
      disabled = _ref3.disabled,
      onTitleClick = _ref3.onTitleClick,
      hasDisableToggle = _ref3.hasDisableToggle,
      name = _ref3.name,
      onDisableToggle = _ref3.onDisableToggle;
  return _react2.default.createElement(Container, {
    open: open
  }, _react2.default.createElement(Title, {
    onClick: disabled ? null : onTitleClick,
    disabled_ie11: disabled
  }, _react2.default.createElement(_FlexWrapper2.default, null, titleContents), hasDisableToggle ? _react2.default.createElement(_CheckboxSwitch2.default, {
    onChange: onDisableToggle,
    name: name + '-toggle',
    value: !disabled
  }) : null, _react2.default.createElement(_IconBox2.default, null, open ? _react2.default.createElement(IsOpenIcon, null) : _react2.default.createElement(IsClosedIcon, null))), _react2.default.createElement(ContentContainer, {
    visible: open && !disabled
  }, _react2.default.createElement(ContentMargin, null, bodyContents)));
}

Accordion.defaultProps = {
  open: false,
  disabled: false,
  hasDisableToggle: false,
  name: '',
  titleContents: null,
  bodyContents: null,
  onDisableToggle: function onDisableToggle() {},
  onTitleClick: function onTitleClick() {}
};