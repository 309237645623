import validate from 'validate.js';

export const required = value => (value ? undefined : 'Required');
export const isValidSiteName = value =>
  validate.single(value, { presence: true, format: '[a-zA-Z ]+' });
export const isValidUrl = value =>
  validate.single(value, { presence: true, url: true });
export const requiredCheckboxGroup = value =>
  validate.isArray(value) && value.length ? undefined : 'Required';
export const isValidHexColour = value =>
  validate.single(value, {
    presence: { allowEmpty: true },
    format: '^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$',
  });
