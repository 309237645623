'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _AccordionList = require('./AccordionList');

Object.defineProperty(exports, 'default', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_AccordionList).default;
  }
});

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}