// @flow
import authSagas from './auth';
import brandSagas from './brand';
import languageSagas from './language';
import userSagas from './users';
import siteSagas from './site';
import routerSaga from './router';

export default [
  ...authSagas,
  ...brandSagas,
  ...languageSagas,
  ...userSagas,
  ...siteSagas,
  ...routerSaga,
];
