'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Modal = require('./Modal');

var _Modal2 = _interopRequireDefault(_Modal);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}
/* eslint-env browser */


var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'HelpIndicator__Container',
  componentId: 'sc-1jkzepa-0'
})(['font-family:', ';background-color:', ';font-size:', ';height:14px;width:14px;font-weight:600;line-height:14px;text-align:center;border:1px solid ', ';border-radius:4px;padding:1px;cursor:help;align-content:center;'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colours.help;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.fontSizeSmall;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colours.secondary02;
});

var HelpIndicator = function (_React$Component) {
  _inherits(HelpIndicator, _React$Component);

  function HelpIndicator() {
    var _ref5;

    var _temp, _this, _ret;

    _classCallCheck(this, HelpIndicator);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref5 = HelpIndicator.__proto__ || Object.getPrototypeOf(HelpIndicator)).call.apply(_ref5, [this].concat(args))), _this), _this.state = {
      position: undefined
    }, _this.position = function (x, y) {
      return {
        x: x,
        y: y,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight
      };
    }, _this.handleMouseEnter = function (_ref6) {
      var clientX = _ref6.clientX,
          clientY = _ref6.clientY;
      return _this.setState({
        position: _this.position(clientX, clientY),
        show: true
      });
    }, _this.handleMouseLeave = function () {
      return _this.setState({
        show: false
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(HelpIndicator, [{
    key: 'render',
    value: function render() {
      var content = this.props.content;
      return _react2.default.createElement(Container, {
        onMouseEnter: this.handleMouseEnter,
        onMouseLeave: this.handleMouseLeave,
        'aria-label': content
      }, '?', this.state.show ? _react2.default.createElement(_Modal2.default, {
        position: this.state.position,
        overlay: false
      }, _react2.default.createElement('p', null, content)) : null);
    }
  }]);

  return HelpIndicator;
}(_react2.default.Component);

exports.default = HelpIndicator;