'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Label;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledLabel = _styledComponents2.default.label.withConfig({
  type: 'Label'
}).withConfig({
  displayName: 'Label__StyledLabel',
  componentId: 'tia839-0'
})(['font-color:', ';font-size:', ';font-family:', ';font-weight:600;display:inline-block;max-width:100%;line-height:2;width:', ';padding-right:0.5em;box-sizing:border-box;'], function (_ref) {
  var large = _ref.large,
      theme = _ref.theme;
  return large ? theme.colours.highlight01 : theme.colours.secondary03;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontSizeFormLabel;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.fontFamily;
}, function (_ref4) {
  var width = _ref4.width;
  return width || '100%';
});

function Label(props) {
  return _react2.default.createElement(StyledLabel, props);
}

Label.defaultProps = {
  large: false,
  children: null
};