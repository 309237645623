'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BoxList;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledBoxList = _styledComponents2.default.div.withConfig({
  displayName: 'BoxList__StyledBoxList',
  componentId: 'sc-12e2itw-0'
})(['display:flex;flex-direction:row;flex-wrap:wrap;padding:', ';'], function (props) {
  return props.theme.layout.doublePadding;
});

function BoxList(props) {
  return _react2.default.createElement(StyledBoxList, props);
}