'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Banner;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var BannerContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Banner__BannerContainer',
  componentId: 'sc-1715wk8-0'
})(['width:100%;background-color:', ';border-bottom:3px solid ', ';'], function (props) {
  return props.theme.colours.secondary01;
}, function (props) {
  return props.theme.colours.primary01;
});

var StyledBanner = _styledComponents2.default.div.withConfig({
  displayName: 'Banner__StyledBanner',
  componentId: 'sc-1715wk8-1'
})(['font-family:', ';font-size:', ';height:100px;display:flex;align-items:center;max-width:1366px;margin:auto;'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontSizeHeader;
});

function Banner(props) {
  return _react2.default.createElement(BannerContainer, null, _react2.default.createElement(StyledBanner, props));
}