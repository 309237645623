/* eslint-env browser */
import 'core-js/fn/object/';
import 'core-js/fn/array/';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import Root from './root';

ReactDOM.render(React.createElement(Root), document.getElementById('root'));
