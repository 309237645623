// @flow
import { put, call } from 'redux-saga/effects';

import type { Site } from '../reducers/site';
import { actions, constants } from '../actions/site';
import type {
  LoadSiteAction,
  LoadSitesAction,
  CreateSiteAction,
  UpdateSiteAction,
} from '../actions/site';
import { actions as routerActions } from '../actions/router';

import { get, put as update, post } from '../../helpers/http';
import watch from '../../helpers/watch';
import { TO } from '../../constants/routes';
import { API_BASE_URL_SITES } from '../../constants/apis';

type EntityResponse<T> = { data: T };
type ListResponse<T> = { data: { contents: T[] } };

export function* loadSites(
  token: string,
  action: LoadSitesAction,
): Generator<*, *, *> {
  const brandId = action.payload;

  try {
    const response: ListResponse<Site> = yield call(get, {
      url: API_BASE_URL_SITES(brandId),
      token,
    });
    yield put(actions.loadSitesSuccessful(response.data.contents));
  } catch (error) {
    yield put(actions.loadSitesFailure(error));
  }
}

export function* loadSite(
  token: string,
  action: LoadSiteAction,
): Generator<*, *, *> {
  const { brandId, siteId } = action.payload;

  try {
    const response: EntityResponse<Site> = yield call(get, {
      url: `${API_BASE_URL_SITES(brandId)}/${siteId}`,
      token,
    });
    yield put(actions.loadSiteSuccess(response.data));
  } catch (error) {
    yield put(actions.loadSiteFailure({ message: error.message }));
  }
}

export function* createSite(
  token: string,
  action: CreateSiteAction,
): Generator<*, *, *> {
  const { brandId, site } = action.payload;
  const url = API_BASE_URL_SITES(brandId);

  try {
    const response: EntityResponse<Site> = yield call(post, {
      url,
      token,
      data: site,
    });
    yield put(actions.createSiteSuccessful(response.data));
    yield put(routerActions.navigate(TO.SITES(brandId)));
  } catch (error) {
    yield put(actions.createSiteFailure(error.response.data));
  }
}

export function* updateSite(
  token: string,
  action: UpdateSiteAction,
): Generator<*, *, *> {
  const { brandId, site } = action.payload;
  const url = `${API_BASE_URL_SITES(brandId)}/${site.id}`;

  try {
    const response: EntityResponse<Site> = yield call(update, {
      url,
      token,
      data: site,
    });
    yield put(actions.updateSiteSuccessful(response.data));
    yield put(routerActions.navigate(TO.SITES(brandId)));
  } catch (error) {
    yield put(actions.updateSiteFailure({ message: error.response.data }));
  }
}

export default [
  watch(constants.LoadSites, loadSites, true),
  watch(constants.LoadSite, loadSite, true),
  watch(constants.CreateSite, createSite, true),
  watch(constants.UpdateSite, updateSite, true),
];
