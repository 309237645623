import { put, select, call } from 'redux-saga/effects';
import {
  arrayPush,
  arrayRemove,
  change,
  stopSubmit,
  formValueSelector,
} from 'redux-form';

import { actions, constants } from '../actions/users';
import { get } from '../../helpers/http';
import { API_BASE_URL_USERS } from '../../constants/apis';

import watch from '../../helpers/watch';

const brandFormName = 'brandForm';
const brandSelector = formValueSelector(brandFormName);

export const fromState = state => ({
  token: state.auth.token,
  address: brandSelector(state, 'address'),
  users: brandSelector(state, 'users'),
});

export function* lookupUser() {
  const { users = [], token, address } = yield select(fromState);

  if (!address) return;

  const normalisedAddress = address
    .trim()
    .toLowerCase()
    // this regex removes non a-z0-9 chars from the start and end of the email
    // it is also in the editor effects/users.js
    .replace(/^[^a-z0-9]*|[^a-z0-9]*$/g, '');

  if (users.some(user => user === normalisedAddress)) {
    yield put(actions.addUserFailure('User has already been added'));
    return;
  }
  try {
    yield call(get, {
      url: `${API_BASE_URL_USERS}/${encodeURIComponent(normalisedAddress)}`,
      token,
    });
    yield put(actions.addUserSuccess(normalisedAddress));
  } catch (error) {
    yield put(actions.addUserFailure('User not found'));
  }
}

function* addUser({ payload: { address } }) {
  yield put(change(brandFormName, 'address', ''));
  yield put(arrayPush(brandFormName, 'users', address));
}

function* addUserFailed({ payload: { error } }) {
  // Manually set error state on the address field
  yield put(stopSubmit(brandFormName, { address: error }));
}

function* removeUser({ payload: { index } }) {
  yield put(arrayRemove(brandFormName, 'users', index));
}

export default [
  watch(constants.addUser, lookupUser),
  watch(constants.addUserSuccess, addUser),
  watch(constants.addUserFailure, addUserFailed),
  watch(constants.removeUser, removeUser),
];
