'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Fieldset;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledFieldset = _styledComponents2.default.fieldset.withConfig({
  displayName: 'Fieldset__StyledFieldset',
  componentId: 'j7hhg9-0'
})(['display:flex;flex-direction:column;border:0;padding-bottom:20px;']);

function Fieldset(props) {
  return _react2.default.createElement(StyledFieldset, props);
}