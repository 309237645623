// @flow
import React from 'react';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import {
  Banner,
  Button,
  Fieldset,
  FormContainer,
  FormGroup,
  InputField,
  Label,
} from 'cms-ui';

import type { Brand } from '../state/reducers/brand';
import type { Site as SiteType } from '../state/reducers/site';
import { isValidSiteName } from '../helpers/validation';
import ErrorMessageWithScrollTo from './ErrorMessageWithScrollTo';

type BrandId = $PropertyType<Brand, 'id'>;

const BannerItems = styled.div`
  display: flex;
  flex: 1 0 0%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ColumnFormContainer = styled(FormContainer)`
  flex-direction: column;
`;

type FormProps = {
  initialValues: Object,
  handleSubmit: Function,
  invalid: boolean,
};

const Form = ({ handleSubmit, invalid, initialValues }: FormProps) => (
  <form onSubmit={handleSubmit}>
    <Fieldset>
      <FormGroup>
        <Label large htmlFor="name">
          Site Name
        </Label>
        <Field
          large
          validate={isValidSiteName}
          name="name"
          component={InputField}
          type="text"
        />
        <Label large htmlFor="make">
          Make
        </Label>
        <Field
          large
          validate={isValidSiteName}
          name="settings.inventory.make"
          component={InputField}
          type="text"
        />
        <Label large htmlFor="market">
          Market
        </Label>
        <Field
          large
          validate={isValidSiteName}
          name="settings.inventory.market"
          component={InputField}
          type="text"
        />
        <Label large htmlFor="country">
          Country
        </Label>
        <Field
          large
          validate={isValidSiteName}
          name="settings.inventory.country"
          component={InputField}
          type="text"
        />
        <Label large htmlFor="inventoryStatus">
          Retail or New?
        </Label>
        <Field
          large
          validate={isValidSiteName}
          name="settings.inventory.inventoryStatus"
          component={InputField}
          type="text"
        />
      </FormGroup>
      <div>
        <Button type="submit" disabled={invalid} fullWidth={false}>
          {initialValues ? 'EDIT SITE' : 'CREATE SITE'}
        </Button>
      </div>
    </Fieldset>
  </form>
);

const SiteForm = reduxForm({
  form: 'createSiteForm',
  enableReinitialize: true,
})(Form);

type SiteProps = {
  site: SiteType,
  error: ?Object,
  createSite: (BrandId, SiteType) => void,
  updateSite: (BrandId, SiteType) => void,
  brandId: BrandId,
};

export default function Site({
  site,
  error,
  createSite,
  updateSite,
  brandId,
}: SiteProps) {
  const handleSubmit = newSite =>
    (site ? updateSite : createSite)(brandId, newSite);

  return (
    <div>
      <Banner>
        <BannerItems>{site ? 'Edit Site' : 'Create Site'}</BannerItems>
      </Banner>
      <ColumnFormContainer>
        {error && <ErrorMessageWithScrollTo error={error} />}
        <SiteForm onSubmit={handleSubmit} initialValues={site} />
      </ColumnFormContainer>
    </div>
  );
}
