// @flow
import React from 'react';
import { Field } from 'redux-form';
import { CheckboxGroup, Fieldset, SectionHeading } from 'cms-ui';

import { requiredCheckboxGroup } from '../helpers/validation';

type Props = {
  pages: { [key: string]: { title: string, components: Object[] } },
};

export default function PagesAndModules({ pages }: Props) {
  return (
    <Fieldset>
      <SectionHeading>PAGES</SectionHeading>
      <Field
        validate={requiredCheckboxGroup}
        component={CheckboxGroup}
        name="pages"
        getLabelFromOption={option => option.label}
        getValueFromOption={option => option.value}
        options={Object.keys(pages).map(key => ({
          value: pages[key].title,
          label: pages[key].title,
        }))}
        hasSelectAll
      />
    </Fieldset>
  );
}
