'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ErrorBanner;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Error = _styledComponents2.default.div.withConfig({
  displayName: 'ErrorBanner__Error',
  componentId: 'sc-1magtwc-0'
})(['font-family:', ';font-size:', ';color:', ';background:', ';border-bottom:1px solid ', ';display:flex;padding:', ';align-items:center;'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontSizeHeader;
}, function (props) {
  return props.theme.colours.danger03;
}, function (props) {
  return props.theme.colours.danger01;
}, function (props) {
  return props.theme.colours.danger02;
}, function (props) {
  return props.theme.layout.standardPadding + ' ' + props.theme.layout.doublePadding;
});

function ErrorBanner(props) {
  return _react2.default.createElement(Error, props);
}