'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TextTransform;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _IconRadioGroup = require('../IconRadioGroup');

var _IconRadioGroup2 = _interopRequireDefault(_IconRadioGroup);

var _LowercaseDisabled = require('./LowercaseDisabled');

var _LowercaseDisabled2 = _interopRequireDefault(_LowercaseDisabled);

var _LowercaseEnabled = require('./LowercaseEnabled');

var _LowercaseEnabled2 = _interopRequireDefault(_LowercaseEnabled);

var _RegularcaseDisabled = require('./RegularcaseDisabled');

var _RegularcaseDisabled2 = _interopRequireDefault(_RegularcaseDisabled);

var _RegularcaseEnabled = require('./RegularcaseEnabled');

var _RegularcaseEnabled2 = _interopRequireDefault(_RegularcaseEnabled);

var _UppercaseDisabled = require('./UppercaseDisabled');

var _UppercaseDisabled2 = _interopRequireDefault(_UppercaseDisabled);

var _UppercaseEnabled = require('./UppercaseEnabled');

var _UppercaseEnabled2 = _interopRequireDefault(_UppercaseEnabled);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var options = [{
  icons: {
    base: _UppercaseDisabled2.default,
    active: _UppercaseEnabled2.default,
    disabled: 'blue'
  },
  value: 'uppercase'
}, {
  icons: {
    base: _RegularcaseDisabled2.default,
    active: _RegularcaseEnabled2.default,
    disabled: 'blue'
  },
  value: 'none'
}, {
  icons: {
    base: _LowercaseDisabled2.default,
    active: _LowercaseEnabled2.default,
    disabled: 'blue'
  },
  value: 'lowercase'
}];

function TextTransform(_ref) {
  var value = _ref.value,
      name = _ref.name,
      open = _ref.open,
      onChange = _ref.onChange,
      onClose = _ref.onClose,
      onOpen = _ref.onOpen,
      inline = _ref.inline;
  var objValue = options.find(function (o) {
    return o.value === value;
  }) || options[0];
  return _react2.default.createElement(_IconRadioGroup2.default, {
    name: name,
    options: options,
    selectedValue: objValue,
    open: inline || open,
    onChange: onChange,
    onClose: onClose,
    onOpen: onOpen,
    inline: inline
  });
}

TextTransform.defaultProps = {
  inline: false
};