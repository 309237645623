'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = IconRadioGroup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* eslint-disable jsx-a11y/label-has-for */


function justifyIcons(inline, options) {
  if (options.length === 2) {
    return 'flex-end';
  }

  if (inline) {
    return 'space-between';
  }

  return 'start';
}

var StyledIconRadioGroup = _styledComponents2.default.div.withConfig({
  displayName: 'IconRadioGroup__StyledIconRadioGroup',
  componentId: 'sc-1a459wh-0'
})(['display:flex;justify-content:', ';'], function (_ref) {
  var inline = _ref.inline,
      options = _ref.options;
  return justifyIcons(inline, options);
});

var StyledRadioContainer = _styledComponents2.default.div.withConfig({
  displayName: 'IconRadioGroup__StyledRadioContainer',
  componentId: 'sc-1a459wh-1'
})(['visibility:', ';margin-top:', ';'], function (props) {
  return props.open ? 'visible' : 'hidden';
}, function (props) {
  return props.inline ? '0' : '-20px';
});

var HiddenRadioButton = _styledComponents2.default.input.withConfig({
  displayName: 'HiddenRadioButton'
}).withConfig({
  displayName: 'IconRadioGroup__HiddenRadioButton',
  componentId: 'sc-1a459wh-2'
})(['display:none;']);

var iconSize = '20px';

var BaseWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'IconRadioGroup__BaseWrapper',
  componentId: 'sc-1a459wh-3'
})(['box-sizing:border-box;background-color:', '};width:', ';height:', ';svg{width:', ';height:', ';}&:hover{cursor:pointer;}'], function (_ref2) {
  var theme = _ref2.theme;
  return theme.colours.primary03;
}, iconSize, iconSize, iconSize, iconSize);

var ActiveWrapper = (0, _styledComponents2.default)(BaseWrapper).withConfig({
  displayName: 'IconRadioGroup__ActiveWrapper',
  componentId: 'sc-1a459wh-4'
})(['', ';'], function (_ref3) {
  var theme = _ref3.theme;
  return theme.mixins.boxShadow(theme.colours.primary01, 0.6, 0, 0, 8, 0, 0);
});

var SelectedImage = function SelectedImage(_ref4) {
  var selectedValue = _ref4.selectedValue,
      open = _ref4.open,
      onClose = _ref4.onClose,
      onOpen = _ref4.onOpen,
      inline = _ref4.inline;
  var Active = selectedValue.icons.active;
  if (inline) return null;
  return _react2.default.createElement(ActiveWrapper, null, _react2.default.createElement(Active, {
    onClick: open ? onClose : onOpen
  }));
};

SelectedImage.defaultProps = {
  inline: false
};

function IconRadioGroup(_ref5) {
  var options = _ref5.options,
      selectedValue = _ref5.selectedValue,
      open = _ref5.open,
      onChange = _ref5.onChange,
      onOpen = _ref5.onOpen,
      onClose = _ref5.onClose,
      name = _ref5.name,
      inline = _ref5.inline;
  var inputs = options.map(function (_ref6, index) {
    var value = _ref6.value,
        icons = _ref6.icons;
    var Active = icons.active,
        Base = icons.base;
    return _react2.default.createElement(StyledRadioContainer, {
      key: value,
      open: open,
      inline: inline
    }, _react2.default.createElement(HiddenRadioButton, {
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (event) {
        return onChange(event.target.value);
      }),
      key: value,
      type: 'radio',
      name: name,
      value: value,
      id: '' + name + index,
      icons: icons
    }), _react2.default.createElement('label', {
      htmlFor: '' + name + index,
      title: value
    }, selectedValue.value === value ? _react2.default.createElement(ActiveWrapper, null, _react2.default.createElement(Active, null)) : _react2.default.createElement(BaseWrapper, null, _react2.default.createElement(Base, null))));
  });
  return _react2.default.createElement('div', null, _react2.default.createElement(StyledIconRadioGroup, {
    inline: inline,
    options: options
  }, inputs), _react2.default.createElement(SelectedImage, {
    selectedValue: selectedValue,
    open: open,
    onOpen: onOpen,
    onClose: onClose,
    inline: inline
  }));
}

IconRadioGroup.defaultProps = {
  name: '',
  options: [],
  value: {},
  open: false,
  onChange: function onChange() {},
  onOpen: function onOpen() {},
  onClose: function onClose() {},
  inline: false
};