// @flow
import React from 'react';
import { connect } from 'react-redux';
import Site from '../components/Site';

import { actions as siteActions } from '../state/actions/site';
import { actions as brandActions } from '../state/actions/brand';

const mapStateToProps = ({ site }) => ({
  sites: site.sites,
  error: site.error,
});

const mapDispatchToProps = dispatch => ({
  createSite: (brandId, site) =>
    dispatch(siteActions.createSite(brandId, site)),
  updateSite: (brandId, site) =>
    dispatch(siteActions.updateSite(brandId, site)),
  loadSite: (brand, siteId) => dispatch(siteActions.loadSite(brand, siteId)),
  loadBrand: brandId => dispatch(brandActions.loadBrand(brandId)),
});

class CreateSiteWithLifecycle extends React.Component<*, *> {
  componentDidMount = () => {
    const {
      params: { siteId, brandId },
    } = this.props.match;
    this.props.loadBrand(brandId);
    if (siteId) this.props.loadSite(brandId, siteId);
  };

  render() {
    const {
      params: { brandId, siteId },
    } = this.props.match;
    const site = this.props.sites[siteId];

    return <Site site={site} brandId={brandId} {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateSiteWithLifecycle);
