'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Column;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledColumn = _styledComponents2.default.div.withConfig({
  displayName: 'Column__StyledColumn',
  componentId: 'pfwuel-0'
})(['display:flex;width:', ';padding-right:', ';padding-left:', ';padding-bottom:', ';padding-top:', ';align-items:', ';flex-direction:column;'], function (_ref) {
  var width = _ref.width;
  return width || '100%';
}, function (props) {
  return props.paddingRight ? props.theme.layout[props.paddingRight] : 0;
}, function (props) {
  return props.paddingLeft ? props.theme.layout[props.paddingLeft] : 0;
}, function (props) {
  return props.paddingBottom ? props.theme.layout[props.paddingBottom] : 0;
}, function (props) {
  return props.paddingTop ? props.theme.layout[props.paddingTop] : 0;
}, function (props) {
  return props.alignItems ? props.alignItems : 'top';
});

function Column(props) {
  return _react2.default.createElement(StyledColumn, props);
}