// @flow
import React from 'react';
import { Field } from 'redux-form';
import {
  CheckboxGroup,
  InputField,
  Fieldset,
  Label,
  FormGroup,
  SectionHeading,
} from 'cms-ui';

import { requiredCheckboxGroup, required } from '../helpers/validation';

type Language = {
  id: String,
  name: String,
  iso: string,
};

type generalDetailsProps = {
  languages: Language[],
};

export default function GeneralDetails({ languages }: generalDetailsProps) {
  return (
    <Fieldset>
      <SectionHeading>GENERAL DETAILS</SectionHeading>
      <FormGroup>
        <Label large htmlFor="name">
          Tenant Name
        </Label>
        <Field
          large
          validate={required}
          name="name"
          component={InputField}
          type="text"
        />
      </FormGroup>
      <FormGroup>
        <Label large htmlFor="domainName">
          Admin URL
        </Label>
        <Field
          large
          validate={required}
          name="domainName"
          component={InputField}
          type="text"
        />
      </FormGroup>
      <FormGroup>
        <Label large htmlFor="languages">
          Select languages
        </Label>
        <Field
          validate={requiredCheckboxGroup}
          name="languages"
          component={CheckboxGroup}
          getLabelFromOption={option => option.name}
          getValueFromOption={option => option.id}
          options={languages}
          hasSelectAll={languages.length > 0}
        />
      </FormGroup>
    </Fieldset>
  );
}

GeneralDetails.defaultProps = {
  languages: [],
};
