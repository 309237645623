'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _cloudinaryReact = require('cloudinary-react');

var _DeleteButton = require('./DeleteButton');

var _DeleteButton2 = _interopRequireDefault(_DeleteButton);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var mediaTypes = ['image', 'video', 'svg'];

var TileContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TileContainer'
}).withConfig({
  displayName: 'MediaGallery__TileContainer',
  componentId: 'sc-13d430l-0'
})(['display:flex;flex-basis:18%;flex-direction:column;padding:1%;width:150px;']);

var MediaGalleryContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MediaGalleryContainer'
}).withConfig({
  displayName: 'MediaGallery__MediaGalleryContainer',
  componentId: 'sc-13d430l-1'
})(['display:flex;flex-wrap:wrap;background-color:', ';padding:1%;'], function (props) {
  return props.theme.colours.secondary01;
});

var TileInfoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TileInfoContainer'
}).withConfig({
  displayName: 'MediaGallery__TileInfoContainer',
  componentId: 'sc-13d430l-2'
})(['background-color:', ';min-height:70px;display:flex;flex-direction:column;justify-content:space-evenly;padding:0 5px;'], function (props) {
  return props.theme.colours.secondary04;
});

var TileMetaContainer = _styledComponents2.default.div.withConfig({
  displayName: 'TileMetaContainer'
}).withConfig({
  displayName: 'MediaGallery__TileMetaContainer',
  componentId: 'sc-13d430l-3'
})(['display:flex;justify-content:space-between;align-items:center;font-size:', ';font-weight:500;'], function (props) {
  return props.theme.fontSizeFormControls;
});

var NavButton = _styledComponents2.default.button.withConfig({
  displayName: 'NavButton'
}).withConfig({
  displayName: 'MediaGallery__NavButton',
  componentId: 'sc-13d430l-4'
})(['height:40px;width:32px;border:1px solid ', ';background-color:', ';cursor:', ';opacity:', ';:after{content:\'\';border-style:solid;border-width:0.15em 0.15em 0 0;display:inline-block;height:0.5em;left:0;position:relative;top:0.3em;transform:rotate(', 'deg);vertical-align:top;width:0.5em;color:', ';}'], function (props) {
  return props.theme.colours.secondary02;
}, function (props) {
  return props.theme.colours.secondary01;
}, function (props) {
  return props.disabled ? 'not-allowed' : 'pointer';
}, function (props) {
  return props.disabled ? '0.5' : '1';
}, function (props) {
  return props.left ? '225' : '45';
}, function (props) {
  return props.theme.colours.primary01;
});

var Pagination = _styledComponents2.default.div.withConfig({
  displayName: 'Pagination'
}).withConfig({
  displayName: 'MediaGallery__Pagination',
  componentId: 'sc-13d430l-5'
})(['display:flex;justify-content:flex-end;align-items:center;']);

var Label = _styledComponents2.default.span.withConfig({
  displayName: 'Label'
}).withConfig({
  displayName: 'MediaGallery__Label',
  componentId: 'sc-13d430l-6'
})(['padding:10px;color:', ';'], function (props) {
  return props.theme.colours.secondary02;
});

var EllipsisLabel = _styledComponents2.default.span.withConfig({
  displayName: 'EllipsisLabel'
}).withConfig({
  displayName: 'MediaGallery__EllipsisLabel',
  componentId: 'sc-13d430l-7'
})(['white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:calc(100% - 10%);']);

var imageTransformationParameter = 'w_150,h_150';

var imageUrl = function imageUrl(baseUrl, format, publicId, version) {
  return [baseUrl, 'image', 'upload', imageTransformationParameter, 'v' + version, publicId].join('/') + '.' + format;
};

var TileInfo = function TileInfo(_ref) {
  var label = _ref.label,
      meta = _ref.meta,
      withDelete = _ref.withDelete,
      onDelete = _ref.onDelete;
  return _react2.default.createElement(TileInfoContainer, {
    'data-qa-hook': label + '-container'
  }, _react2.default.createElement(EllipsisLabel, {
    'data-qa-hook': label + '-label'
  }, label), _react2.default.createElement(TileMetaContainer, null, _react2.default.createElement('span', {
    'data-qa-hook': label + '-meta'
  }, meta && meta.width + ' x ' + meta.height + ', ' + meta.size), withDelete && _react2.default.createElement(_DeleteButton2.default, {
    'data-qa-hook': label + '-delete',
    onClick: onDelete
  })));
};

var withMedia = function withMedia(Component) {
  return function (_ref2) {
    var _ref2$item = _ref2.item,
        label = _ref2$item.label,
        publicId = _ref2$item.publicId,
        meta = _ref2$item.meta,
        withDelete = _ref2$item.withDelete,
        onDelete = _ref2.onDelete,
        onSelect = _ref2.onSelect;
    return _react2.default.createElement(TileContainer, null, _react2.default.createElement(Component, {
      publicId: publicId,
      version: meta && meta.version,
      format: meta && meta.format,
      onSelect: function (_onSelect) {
        function onSelect() {
          return _onSelect.apply(this, arguments);
        }

        onSelect.toString = function () {
          return _onSelect.toString();
        };

        return onSelect;
      }(function () {
        return onSelect({
          label: label,
          publicId: publicId
        });
      })
    }), _react2.default.createElement(TileInfo, {
      label: '' + label + (meta && meta.format ? '.' + meta.format : ''),
      meta: meta,
      withDelete: withDelete,
      onDelete: function (_onDelete) {
        function onDelete() {
          return _onDelete.apply(this, arguments);
        }

        onDelete.toString = function () {
          return _onDelete.toString();
        };

        return onDelete;
      }(function () {
        return onDelete({
          label: label,
          publicId: publicId
        });
      })
    }));
  };
};

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'Image'
}).withConfig({
  displayName: 'MediaGallery__Image',
  componentId: 'sc-13d430l-8'
})(['cursor:', ';height:150px;object-fit:cover;object-position:50% 50%;'], function (props) {
  return props.mode === 'Module' ? 'pointer' : 'unset';
});

var VideoWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VideoWrapper'
}).withConfig({
  displayName: 'MediaGallery__VideoWrapper',
  componentId: 'sc-13d430l-9'
})(['display:inline-block;position:relative;width:100%;height:100%;cursor:', ';'], function (props) {
  return props.mode === 'Module' ? 'pointer' : 'unset';
});

var VideoComponent = function VideoComponent(accountName, mode) {
  return function (_ref3) {
    var publicId = _ref3.publicId,
        onSelect = _ref3.onSelect;
    return _react2.default.createElement(VideoWrapper, {
      mode: mode,
      onClick: mode === 'Module' ? onSelect : null
    }, _react2.default.createElement(_cloudinaryReact.Video, {
      width: '100%',
      height: '100%',
      cloudName: accountName,
      publicId: publicId,
      controls: mode === 'Global',
      'data-qa-hook': publicId + '-video'
    }));
  };
};

var ImageComponent = function ImageComponent(baseUrl, mode) {
  return function (_ref4) {
    var publicId = _ref4.publicId,
        format = _ref4.format,
        version = _ref4.version,
        onSelect = _ref4.onSelect;
    return _react2.default.createElement(Image, {
      mode: mode,
      src: imageUrl(baseUrl, format, publicId, version || 1),
      onClick: mode === 'Module' ? onSelect : null,
      'data-qa-hook': publicId + '-image'
    });
  };
};

var MediaGallery = function (_React$Component) {
  _inherits(MediaGallery, _React$Component);

  function MediaGallery() {
    var _ref5;

    var _temp, _this, _ret;

    _classCallCheck(this, MediaGallery);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref5 = MediaGallery.__proto__ || Object.getPrototypeOf(MediaGallery)).call.apply(_ref5, [this].concat(args))), _this), _this.state = {
      currentPage: 1,
      itemsPerPage: 10
    }, _this.onPrevNav = function () {
      return _this.setState(function (_ref6) {
        var currentPage = _ref6.currentPage;
        return {
          currentPage: currentPage - 1
        };
      });
    }, _this.onNextNav = function () {
      return _this.setState(function (_ref7) {
        var currentPage = _ref7.currentPage;
        return {
          currentPage: currentPage + 1
        };
      });
    }, _this.maxPageNumber = Math.ceil(_this.props.items.length / _this.state.itemsPerPage), _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(MediaGallery, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          items = _props.items,
          type = _props.type,
          accountName = _props.accountName,
          onDeleteItem = _props.onDeleteItem,
          onSelectItem = _props.onSelectItem,
          mediaBaseUrl = _props.mediaBaseUrl,
          mode = _props.mode;
      var _state = this.state,
          currentPage = _state.currentPage,
          itemsPerPage = _state.itemsPerPage;

      if (mediaTypes.includes(type)) {
        var Tile = withMedia(type === 'image' ? ImageComponent(mediaBaseUrl, mode) : VideoComponent(accountName, mode));
        var lastItemIndex = currentPage * itemsPerPage;
        var firstItemIndex = lastItemIndex - itemsPerPage;
        var itemsToDisplay = items.slice(firstItemIndex, lastItemIndex);
        return _react2.default.createElement('div', null, _react2.default.createElement(MediaGalleryContainer, null, itemsToDisplay.map(function (_ref8) {
          var label = _ref8.label,
              publicId = _ref8.publicId,
              meta = _ref8.meta,
              withDelete = _ref8.withDelete;
          return _react2.default.createElement(Tile, {
            key: '' + publicId,
            onSelect: onSelectItem,
            onDelete: onDeleteItem,
            item: {
              label: label,
              publicId: publicId,
              meta: meta,
              withDelete: withDelete
            }
          });
        })), items.length > 0 && _react2.default.createElement(Pagination, null, _react2.default.createElement(Label, null, firstItemIndex + 1 + ' - ' + (lastItemIndex > items.length ? items.length : lastItemIndex) + ' of ' + items.length), _react2.default.createElement(NavButton, {
          onClick: this.onPrevNav,
          disabled: currentPage === 1,
          left: true
        }), _react2.default.createElement(NavButton, {
          onClick: this.onNextNav,
          disabled: this.maxPageNumber === currentPage
        })));
      }

      return null;
    }
  }]);

  return MediaGallery;
}(_react2.default.Component);

MediaGallery.defaultProps = {
  items: [],
  type: 'image',
  accountName: '',
  mediaBaseUrl: '',
  onDeleteItem: function onDeleteItem() {},
  onSelectItem: function onSelectItem() {},
  mode: 'Global'
};
exports.default = MediaGallery;