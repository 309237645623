'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CheckboxGroupSelectAll;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Checkbox = require('./Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var SelectAllContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CheckboxGroupSelectAll__SelectAllContainer',
  componentId: 'sc-1b8q23f-0'
})(['border-bottom:1px solid ', ';margin:10px 0;'], function (props) {
  return props.theme.colours.secondary02;
});

function CheckboxGroupSelectAll(_ref) {
  var input = _ref.input,
      options = _ref.options;
  var value = input.value || [];
  var checked = value.length === options.length;

  var selectAll = function selectAll(newChecked) {
    return input.onChange(newChecked ? [].concat(_toConsumableArray(options)) : []);
  };

  return _react2.default.createElement(SelectAllContainer, null, _react2.default.createElement(_Checkbox2.default, {
    checked: checked,
    onChange: selectAll,
    label: 'Select All'
  }));
}