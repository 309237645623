"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var GlobalSettingsIcon = function GlobalSettingsIcon(props) {
  return _react2.default.createElement("svg", _extends({
    width: "1.5em",
    height: "1.5em",
    viewBox: "0 0 24 24"
  }, props), _react2.default.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, _react2.default.createElement("g", {
    transform: "translate(2 2)"
  }, _react2.default.createElement("rect", {
    fill: "#0065DB",
    x: 2,
    width: 1,
    height: 21,
    rx: 0.5
  }), _react2.default.createElement("circle", {
    stroke: "#0065DB",
    fill: "#FFF",
    cx: 2.5,
    cy: 6.5,
    r: 2.5
  })), _react2.default.createElement("g", {
    transform: "translate(9 2)"
  }, _react2.default.createElement("rect", {
    fill: "#0065DB",
    x: 2,
    width: 1,
    height: 21,
    rx: 0.5
  }), _react2.default.createElement("circle", {
    stroke: "#0065DB",
    fill: "#FFF",
    cx: 2.5,
    cy: 15.5,
    r: 2.5
  })), _react2.default.createElement("g", {
    transform: "translate(16 1)"
  }, _react2.default.createElement("rect", {
    fill: "#0065DB",
    x: 2,
    y: 1,
    width: 1,
    height: 21,
    rx: 0.5
  }), _react2.default.createElement("circle", {
    stroke: "#0065DB",
    fill: "#FFF",
    cx: 2.5,
    cy: 2.5,
    r: 2.5
  }))));
};

exports.default = GlobalSettingsIcon;