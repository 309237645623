'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ColourSelection;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

var _ = require('.');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledSelect = (0, _styledComponents2.default)(_reactSelect2.default).withConfig({
  displayName: 'StyledSelect'
}).withConfig({
  displayName: 'ColourSelection__StyledSelect',
  componentId: 'sc-1rahbtb-0'
})(['border-radius:0;font-family:', ';font-size:', ';& .Select-value{background:none;padding:0 !important;}& .Select-placeholder{&:hover{color:#474747;}}& .Select-control{background:none;border-radius:0;border:none;&:hover{box-shadow:none;cursor:pointer;}}&.is-focused:not(.is-open) > .Select-control{box-shadow:none;background-color:transparent;}& .Select-clear-zone,.Select-arrow-zone{margin:0;padding:0;width:0;}'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontSizeContent;
});

var hideIcons = function hideIcons() {
  return _react2.default.createElement('span', null);
};

var colourFieldRenderer = function colourFieldRenderer(_ref) {
  var label = _ref.label,
      value = _ref.value;
  return _react2.default.createElement(_.ColourField, {
    label: label,
    value: value
  });
};

function ColourSelection(_ref2) {
  var input = _ref2.input,
      options = _ref2.options;
  return _react2.default.createElement(StyledSelect, {
    value: input.value,
    options: options,
    optionRenderer: colourFieldRenderer,
    valueRenderer: colourFieldRenderer,
    onChange: input.onChange,
    arrowRenderer: null,
    placeholder: 'Template Colour',
    clearRenderer: hideIcons
  });
}