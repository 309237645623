'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

exports.default = CreatePage;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _PageSeo = require('./PageSeo');

var _PageSeo2 = _interopRequireDefault(_PageSeo);

var _PageDetails = require('./PageDetails');

var _PageDetails2 = _interopRequireDefault(_PageDetails);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'CreatePage__Container',
  componentId: 'ym8sfb-0'
})(['font-family:', ';font-size:', ';display:flex;flex-direction:column;'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontSizeContent;
});

var Section = _styledComponents2.default.div.withConfig({
  displayName: 'CreatePage__Section',
  componentId: 'ym8sfb-1'
})(['', ';', ' ', ''], function (_ref3) {
  var topSection = _ref3.topSection,
      theme = _ref3.theme;
  return topSection ? 'padding: ' + theme.layout.halfPadding + ' 10px' : 'padding: 0 10px';
}, function (_ref4) {
  var topSection = _ref4.topSection,
      theme = _ref4.theme;
  return topSection ? 'margin: ' + theme.layout.standardPadding + ' 0;' : '';
}, function (_ref5) {
  var topSection = _ref5.topSection,
      theme = _ref5.theme;
  return topSection ? 'border-bottom: 1px solid ' + theme.colours.secondary02 + ';' : '';
});

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CreatePage__ButtonContainer',
  componentId: 'ym8sfb-2'
})(['display:flex;flex-direction:column;margin:12px;']);

function CreatePage(_ref6) {
  var pageTemplates = _ref6.pageTemplates,
      value = _ref6.value,
      onChange = _ref6.onChange,
      renderButton = _ref6.renderButton,
      canChangePageType = _ref6.canChangePageType;
  return _react2.default.createElement(Container, null, _react2.default.createElement(Section, {
    topSection: true
  }, _react2.default.createElement(_PageDetails2.default, {
    pageTemplates: pageTemplates,
    value: value,
    onChange: onChange,
    canChangeType: canChangePageType
  })), _react2.default.createElement(Section, null, _react2.default.createElement(_PageSeo2.default, {
    value: value.seo,
    onChange: function (_onChange) {
      function onChange(_x) {
        return _onChange.apply(this, arguments);
      }

      onChange.toString = function () {
        return _onChange.toString();
      };

      return onChange;
    }(function (seo) {
      return onChange(_extends({}, value, {
        seo: seo
      }));
    })
  }), renderButton && _react2.default.createElement(ButtonContainer, null, renderButton(value))));
}