// @flow
import React from 'react';
import styled from 'styled-components';
import { Banner, Box, BoxList, Button, ErrorBanner } from 'cms-ui';
import { type Error } from '../state/types/error';

import { type Brand } from '../state/reducers/brand';
import ROUTES, { TO } from '../constants/routes';

const BrandName = styled.div`
  flex: 1 0 0%;
`;

const BannerItems = styled.div`
  display: flex;
  flex: 1 0 0%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

type props = {
  brands: { [id: $PropertyType<Brand, 'id'>]: Brand },
  onNavigate: string => void,
  errors: ?(Error[]),
};

// Use of `any` type below is due to https://github.com/facebook/flow/issues/2221
export default function Brands({
  brands,
  onNavigate: navigate,
  errors,
}: props) {
  return (
    <div>
      <Banner>
        <BannerItems>
          Brands
          <Button onClick={() => navigate(ROUTES.CREATE_BRAND)}>
            CREATE BRANDS
          </Button>
        </BannerItems>
      </Banner>
      <div>
        {errors &&
          errors.map(e => <ErrorBanner key={e.key}>{e.message}</ErrorBanner>)}
      </div>
      <BoxList>
        {Object.values(brands).map((b: any) => (
          <Box key={b.id}>
            <BrandName>{b.name}</BrandName>
            <button type="button" onClick={() => navigate(TO.EDIT_BRAND(b.id))}>
              Edit
            </button>
            <button type="button" onClick={() => navigate(TO.SITES(b.id))}>
              Site List
            </button>
          </Box>
        ))}
      </BoxList>
    </div>
  );
}
