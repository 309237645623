'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mixins = undefined;

var _transparentize = require('polished/lib/color/transparentize');

var _transparentize2 = _interopRequireDefault(_transparentize);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var boxShadow = function boxShadow(colourHex, opacity, xOffset, yOffset, blur, spread, inset) {
  return 'box-shadow:\n  ' + (xOffset ? xOffset + 'px' : 0) + '\n  ' + (yOffset ? yOffset + 'px' : 0) + '\n  ' + (blur ? blur + 'px' : 0) + '\n  ' + (spread ? spread + 'px' : 0) + '\n  ' + (0, _transparentize2.default)(opacity, colourHex) + ';\n  ' + (inset ? 'inset' : '') + '\n';
};

var mixins = exports.mixins = {
  boxShadow: boxShadow
};
var theme = {
  bulletPointSize: '8px',
  fontFamily: 'Lato, Helvetica, open sans',
  fontSizeHeader: '20px',
  fontSizeSubHeader: '16px',
  fontSizeContent: '14px',
  fontSizeFormLabel: '13px',
  fontSizeFormControls: '12px',
  fontSizeSmall: '11px',
  colours: {
    primary01: '#0065DB',
    primary02: '#00418E',
    primary03: '#FFFFFF',
    secondary01: '#FFFFFF',
    secondary02: '#D6D6D6',
    secondary03: '#474747',
    secondary04: '#F3F3F3',
    secondary05: '#444444',
    secondary06: '#373737',
    danger01: '#FF4949',
    danger02: '#AC2D2D',
    danger03: '#FFFFFF',
    disabled01: '#757575',
    disabled02: '#D6D6D6',
    highlight01: '#757575',
    highlight02: '#FFFFFF',
    lowlight: '#D6D6D6',
    action: '#9E1B32',
    connectAutoBlue: '#2078DC',
    linkAction: '#9E1B32',
    placeholder: '#A0A0A0',
    help: '#FFF3BC',
    success: '#74BC25'
  },
  layout: {
    halfPadding: '9px',
    standardPadding: '18px',
    doublePadding: '36px'
  },
  mixins: mixins
};
exports.default = theme;