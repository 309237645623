'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Form;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledForm = _styledComponents2.default.form.withConfig({
  displayName: 'Form__StyledForm',
  componentId: 'zy76il-0'
})(['flex:1 0 0%;']);

function Form(props) {
  return _react2.default.createElement(StyledForm, props);
}