'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var updateItem = exports.updateItem = function updateItem(id, value, items) {
  var index = items.findIndex(function (i) {
    return i.id === id;
  });
  return [].concat(_toConsumableArray(items.slice(0, index)), [_extends({}, items[index], {
    active: value
  })], _toConsumableArray(items.slice(index + 1)));
};