'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RadioGroup;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _androidRadioButtonOn = require('react-icons/lib/io/android-radio-button-on');

var _androidRadioButtonOn2 = _interopRequireDefault(_androidRadioButtonOn);

var _androidRadioButtonOff = require('react-icons/lib/io/android-radio-button-off');

var _androidRadioButtonOff2 = _interopRequireDefault(_androidRadioButtonOff);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'RadioGroup__Container',
  componentId: 'sc-1lgjrz2-0'
})(['font-family:', ';color:', ';display:flex;flex-direction:column;'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.colours.secondary03;
});

var Option = _styledComponents2.default.div.withConfig({
  displayName: 'Option'
}).withConfig({
  displayName: 'RadioGroup__Option',
  componentId: 'sc-1lgjrz2-1'
})(['display:flex;cursor:pointer;margin-bottom:9px;']);

var OptionLabel = _styledComponents2.default.span.withConfig({
  displayName: 'RadioGroup__OptionLabel',
  componentId: 'sc-1lgjrz2-2'
})(['font-size:', ';'], function (props) {
  return props.theme.fontSizeContent;
});

var ThemedIcon = function ThemedIcon(icon, name) {
  return (0, _styledComponents2.default)(icon).withConfig({
    displayName: name
  }).withConfig({
    displayName: 'RadioGroup',
    componentId: 'sc-1lgjrz2-3'
  })(['color:', ';margin-right:7px;'], function (props) {
    return props.theme.colours.primary01;
  });
};

var RadioOn = ThemedIcon(_androidRadioButtonOn2.default, 'RadioOn');
var RadioOff = ThemedIcon(_androidRadioButtonOff2.default, 'RadioOff');

var Radio = function Radio(_ref) {
  var on = _ref.on;
  return on ? _react2.default.createElement(RadioOn, null) : _react2.default.createElement(RadioOff, null);
};

function RadioGroup(_ref2) {
  var options = _ref2.options,
      getLabelFromOption = _ref2.getLabelFromOption,
      getValueFromOption = _ref2.getValueFromOption,
      onChange = _ref2.onChange,
      value = _ref2.value;

  var defaultGetLabel = function defaultGetLabel(o) {
    return o.label;
  };

  var defaultGetValue = function defaultGetValue(o) {
    return o.value;
  };

  var getLabel = getLabelFromOption || defaultGetLabel;
  var getValue = getValueFromOption || defaultGetValue;
  return _react2.default.createElement(Container, null, options.map(function (o) {
    return _react2.default.createElement(Option, {
      key: getValue(o),
      onClick: function onClick() {
        return onChange(o);
      }
    }, _react2.default.createElement(Radio, {
      on: !!value && getValue(o) === getValue(value)
    }), _react2.default.createElement(OptionLabel, null, getLabel(o)));
  }));
}

RadioGroup.defaultProps = {
  getLabelFromOption: function getLabelFromOption(opt) {
    return opt.label;
  },
  getValueFromOption: function getValueFromOption(opt) {
    return opt.value;
  },
  value: null
};