const appSettings = window.AppSettings || {};
const authOn = appSettings.authOn === undefined || appSettings.authOn;
const redirectUri = appSettings.redirectUri || 'http://localhost:3003/';
const domain = process.env.REACT_APP_DOMAIN || 'connect-auto.eu.auth0.com';
const clientId =
  process.env.REACT_APP_CLIENT_ID || 'ghUMgwjDawXiv8hlQwcwIx2X7ntGdxmZ';
const apiAudience =
  process.env.REACT_APP_API_AUDIENCE || 'https://dev.admin.avl-builder.com';
const authorisationScopes =
  process.env.REACT_APP_AUTHORISATION_SCOPES || 'openid edit:brands edit:sites';

const settings = {
  authOn,
  redirectUri,
  domain,
  clientId,
  apiAudience,
  authorisationScopes,
};

export default settings;
