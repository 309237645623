'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SectionHeading;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledSectionHeading = _styledComponents2.default.h2.withConfig({
  displayName: 'SectionHeading__StyledSectionHeading',
  componentId: 'sc-1jda9q8-0'
})(['color:', ';font-size:24px;text-transform:uppercase;margin:0;'], function (props) {
  return props.theme.colours.secondary03;
});

function SectionHeading(props) {
  return _react2.default.createElement(StyledSectionHeading, props);
}