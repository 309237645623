'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = InlineError;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledInlineError = _styledComponents2.default.div.withConfig({
  displayName: 'InlineError'
}).withConfig({
  displayName: 'InlineError__StyledInlineError',
  componentId: 'sc-1gonpo5-0'
})(['color:', ';padding:10px 2px 2px 2px;font-family:', ';'], function (props) {
  return props.theme.colours.danger01;
}, function (props) {
  return props.theme.fontFamily;
});

function InlineError(_ref) {
  var children = _ref.children;
  return _react2.default.createElement(StyledInlineError, null, children);
}

InlineError.defaultProps = {
  children: null
};